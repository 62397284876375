<template>
    <b-navbar class="is-primary" style="padding: 0px 50px 0px 50px">
        <template slot="brand">
            <b-navbar-item tag="router-link" :to="{ path: '/' }">
                <!-- <img
                    src="https://raw.githubusercontent.com/buefy/buefy/dev/static/img/buefy-logo.png"
                    alt="Lightweight UI components for Vue.js based on Bulma"
                > -->
            </b-navbar-item>
        </template>
        <template slot="start">
            <b-navbar-item href="#">
                Home
            </b-navbar-item>
           
        </template>

        <template slot="end">
            <b-navbar-item tag="div">
                <!-- <div class="buttons">
                    <a class="button is-primary">
                        <strong>Sign up</strong>
                    </a>
                    <a class="button is-light">
                        Log in
                    </a>
                </div> -->
                <b-field grouped position="is-right">
                    <p class="control">
                    <b-button style="margin-top: 0px" @click="logout" outlined class="is-white">logout</b-button>
                    </p>
                    <!-- <p class="control">
                    <b-button style="margin-top: 0px" outlined class="is-white">next</b-button>
                    </p> -->
                </b-field>
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>
import {UserService} from '../services/user.service'
import {TokenService} from '../services/storage.service'
import {mapGetters, mapActions} from "vuex";

export default {
  name: 'Navbar',
  props: ['loginbtn'],
  methods: {
    ...mapActions('auth', [
          'logout'
    ]),
  },
  computed: {
      ...mapGetters('auth', [
          'loggedIn',
      ])
  }
}

</script>