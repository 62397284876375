import {QueryService} from '../../services/query.service'
import {helpers} from '@/utils/helpers'

const state =  {
    patientInfo: null,
    ICD10Data: null, // icd10 definitions
    modifierData: null, // modifier definitions
    campaignData: null,
    ICD10Loading: false,
    ICD10UpdatesLoading: [],
    ICD10DeleteLoading: [],
    ICD10Error: null,
    patientICD10Diagnoses: null, // icd10 diagnoses from historical record (if applicable)
    patientModifiers: null // modifiers from historical record (if applicable)
}

const getters = {
    patientInfo: (state) => {
        return state.patientInfo
    },
    ICD10Data: (state) => {
        return state.ICD10Data
    },
    modifierData: (state) => {
        return state.modifierData
    },
    campaignData: (state) => state.campaignData,
    ICD10DBLoading: (state) => state.ICD10Loading,
    ICD10Error: (state) => state.ICD10Error,
    ICD10UpdatesLoading: state => state.ICD10UpdatesLoading,
    ICD10DeleteLoading: state => state.ICD10DeleteLoading,
    patientICD10Diagnoses: state => state.patientICD10Diagnoses,
    patientModifiers: state => state.patientModifiers
}

const actions = {
    async loadICD10DB({ commit, state}) {
        state.ICD10Loading = true
        try {
            const icd10db = await QueryService.loadICD10DB()
            if('data' in icd10db && 'code' in icd10db.data[0]){
                state.ICD10Data = helpers.mapByKey(icd10db.data, 'code')
            }else{
                commit('icd10Error', {errorCode: 500, errorMessage: 'received ICD10 data in an unexpected state'})
            }            
            state.ICD10Loading = false
        } catch (e) {
            commit('icd10Error', {errorCode: e.errorCode || 500, errorMessage: e.errorMessage || 'unknown error'})
            throw e
        }
    },
    async updateICD10({ state}, {items}) {
        state.ICD10UpdatesLoading = items.map(obj=>obj.code)
        try {
            await QueryService.updateICD10DB(items)
            items.forEach(obj => {
                state.ICD10Data[obj.code] = obj
            })        
        } catch (e) {
            // commit('icd10Error', {errorCode: e.errorCode || 500, errorMessage: e.errorMessage || 'unknown error'})
            throw e
        }
        state.ICD10UpdatesLoading = []
    },
    async createICD10({ state}, {item}) {
        state.ICD10UpdatesLoading = [item.code]
        try {
            await QueryService.addICD10DB(item)
            state.ICD10Data[item.code] = item
           
        } catch (e) {
            // commit('icd10Error', {errorCode: e.errorCode || 500, errorMessage: e.errorMessage || 'unknown error'})
            throw e
        }
        state.ICD10UpdatesLoading = []
    },
    async deleteICD10({ commit, state}, {items}) {
        state.ICD10DeleteLoading = items.map(obj=>obj.code)
        try {
            await QueryService.deleteICD10DB(items)
            items.forEach(obj => {
                delete state.ICD10Data[obj.code]
            })        
        } catch (e) {
            // commit('icd10Error', {errorCode: e.errorCode || 500, errorMessage: e.errorMessage || 'unknown error'})
            throw e
        }
        state.ICD10DeleteLoading = []
    }
}
const mutations = {
    icd10Error(state, err){
        state.ICD10Error = err
    },
    setPatientInfo(state, info) {
        state.patientInfo = info
    },
    setICD10Data(state, data) {
        state.ICD10Data = data
    },
    setPatientICD10Diagnoses(state, data) {
        state.patientICD10Diagnoses = data
    },
    setPatientModifiers(state, data){
        state.patientModifiers = data
    },
    setModifierData(state, data) {
        state.modifierData = data
    },
    setCampaignData(state, data) {
        state.campaignData = data
    },
    clearData(state) {
        state.campaignData = null
        state.patientInfo = null 
        state.modifierData = null
        state.ICD10Data = null
    },
    newCase(state){
        state.patientInfo = null
        state.ICD10Data = null
        state.modifierData = null
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}