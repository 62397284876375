<template>
  <div class="container">
    <div class="columns" style="padding-top: 10px">
      <div class="column is-11">
        <p class="title is-4 has-text-primary" style="margin-bottom: 10px">
          Modifiers
        </p>
        <b-collapse :open="false" position="is-bottom" style="margin-top: 0px">
          <a slot="trigger" slot-scope="props" class="has-text-primary-alt">
            {{ !props.open ? "What are modifiers?" : "Hide" }}
          </a>
          <p>
            Modifiers, captured by the fields below, affect the initially calculated RTW prediction. Modifiers only become available after "conversations" with the claimant, caregivers, family and
            their doctors and rehabilitation teams. Modifiers are dynamic and can change over time with regular psychosocial support and clinical interaction.
          </p>
        </b-collapse>
      </div>
    </div>

    <div class="columns">
      <div class="column is-7">
        <div v-if="modifierData && 'categorical' in modifierData">
          <div v-for="(obj, i) in modifierData.categorical" :key="i" style="padding-bottom: 30px">
            <p class="title is-6" style="margin-bottom: 5px">{{ obj.label }}</p>
            <b-collapse v-if="obj.description.length > 0" :open="false" position="is-bottom" style="margin-top: 0px">
              <a slot="trigger" slot-scope="props" class="has-text-primary-alt">
                <p class="heading">{{ !props.open ? "description" : "hide" }}</p>
              </a>
              <p style="padding: 10px 0px 10px 0px; white-space: pre-line;" class="subtitle is-6">
                {{ formatDescr(obj.description) }}
              </p>
            </b-collapse>
            <b-field class="has-text-left">
              <b-select expanded placeholder="Select" v-model="modifierSelectionCat[obj.field]">
                <option v-for="(value, idx) in obj.options" :value="value.name" :key="idx">
                  {{ value.name }}
                </option>
              </b-select>
            </b-field>
          </div>
        </div>

        <div v-if="modifierData && 'range' in modifierData">
          <div v-for="(obj, i) in modifierData.range" :key="i" style="padding-bottom: 20px">
            <b-collapse v-if="obj.description.length > 0" :open="false" position="is-bottom" style="margin-top: 0px">
              <a slot="trigger" slot-scope="props" class="has-text-primary-alt">
                <p class="heading">{{ !props.open ? "description" : "hide" }}</p>
              </a>
              <p style="padding: 10px 0px 10px 0px; white-space: pre-line;" class="subtitle is-6">
                {{ formatDescr(obj.description) }}
              </p>
            </b-collapse>
            <b-field class="has-text-left">
              <b-input v-model="modifierSelectionRange[obj.field]" type="number"> </b-input>
            </b-field>
          </div>
        </div>
      </div>
      <div class="column is-6"></div>
    </div>

    <div class="has-text-right">
      <b-field grouped position="is-right">
        <p class="control">
          <b-button style="margin-top: 0px" @click="back" class="is-primary is-medium">back</b-button>
        </p>
        <p class="control">
          <b-button style="margin-top: 0px" @click="submit" class="is-primary is-medium">next</b-button>
        </p>
      </b-field>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { QueryService } from "@/services/query.service";

export default {
  name: "ModifierSection",
  components: {},
  data() {
    return {
      section: 0,
      modifierSelectionCat: {}, //bindings for modifier selections
      modifierSelectionRange: {},
      modifierData: null
    };
  },
  created() {
    this.modifierData = this.getModifierDefinitions();
  },
  computed: {
    ...mapGetters("dashboard", ["patientModifiers"])
  },
  watch: {
    modifierData(data) {
      if (data && this.patientModifiers) {
        console.log(this.patientModifiers);
        this.populatePatientModifiers();
      }
    },
    patientModifiers(data) {
      this.populatePatientModifiers();
    }
  },
  methods: {
    populatePatientModifiers() {
      if (this.patientModifiers) {
        if ("categorical" in this.patientModifiers) {
          Object.keys(this.patientModifiers.categorical).map((key, idx) => {
            this.modifierSelectionCat[key] = this.patientModifiers.categorical[key].value
          });          
        }
        if ("range" in this.patientModifiers) {
          Object.keys(this.patientModifiers.range).map((key, idx) => {
            this.modifierSelectionRange[key] = this.patientModifiers.range[key].value
          }); 
        }
      }
    },
    formatDescr(d) {
      return d.replace("\n", "\n\n");
    },
    back() {
      this.$emit("navigateBack");
    },
    submit() {
      this.$emit("submit", this.compileModifierData());
    },
    prepopulateModifiers() {
      //populate initial default values
      if ("categorical" in this.modifierData) {
        this.modifierData.categorical.forEach(obj => {
          let defaultField = obj.options[0]["name"]; //assign first score as defualt
          this.$set(this.modifierSelectionCat, obj.field, defaultField);
        });
      }
      if ("range" in this.modifierData) {
        this.modifierData.range.forEach(obj => {
          this.$set(this.modifierSelectionRange, obj.field, 0); //assign default score
        });
      }
    },
    getBandScore(value, bands) {
      let score = null;
      bands.forEach(band => {
        console.log(band.bounds);
        if (value >= band.bounds[0] && value <= band.bounds[1]) {
          score = band.score;
        }
      });
      return score; // either value out of range or variable name not defined
    },
    compileModifierData() {
      let outputCat = {};
      let outputRange = {};

      this.modifierData.categorical.forEach(obj => {
        let selectedVal = this.modifierSelectionCat[obj.field];
        let score = obj.options.filter(op => op.name == selectedVal)[0].score;
        outputCat[obj.field] = { value: selectedVal, score: score };
      });

      this.modifierData.range.forEach(obj => {
        let selectedVal = this.modifierSelectionRange[obj.field]; // real value
        let score = this.getBandScore(selectedVal, obj.bands);
        outputRange[obj.field] = { value: selectedVal, score: score };
      });

      return { categorical: outputCat, range: outputRange };
    },
    getModifierDefinitions() {
      QueryService.loadModifiers()
        .then(res => {
          this.modifierData = res.data;
          this.prepopulateModifiers();
        })
        .catch(res => console.log("Error retrieving modifier data"));
    }
  }
};
</script>
