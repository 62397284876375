import { ToastProgrammatic as toast } from "buefy";
var helpers = {
    numericOnly: (event) => (event.charCode !=8 && event.charCode ==0 || ( event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57))),
    mapByKey: (arr, key) => {
        let outputObj = {}
        arr.forEach((obj)=> {
            let objCopy = JSON.parse(JSON.stringify(obj))
            outputObj[obj[key]] = objCopy
        })
        return outputObj
    },
    deepCopy(obj){
      return JSON.parse(JSON.stringify(obj))
    },
    downloadObjectAsJson(exportObj, exportName){
      var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj, null, 2));
      var downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute("href",     dataStr);
      downloadAnchorNode.setAttribute("download", exportName + ".json");
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
    compareObjects(x, y) {
      if ( x === y ) return true;
        // if both x and y are null or undefined and exactly the same
    
      if ( ! ( x instanceof Object ) || ! ( y instanceof Object ) ) return false;
        // if they are not strictly equal, they both need to be Objects
    
      if ( x.constructor !== y.constructor ) return false;
        // they must have the exact same prototype chain, the closest we can do is
        // test there constructor.
    
      for ( var p in x ) {
        if ( ! x.hasOwnProperty( p ) ) continue;
          // other properties were tested using x.constructor === y.constructor
    
        if ( ! y.hasOwnProperty( p ) ) return false;
          // allows to compare x[ p ] and y[ p ] when set to undefined
    
        if ( x[ p ] === y[ p ] ) continue;
          // if they have the same strict value or identity then they are equal
    
        if ( typeof( x[ p ] ) !== "object" ) return false;
          // Numbers, Strings, Functions, Booleans must be strictly equal
    
        if ( ! compareObjects( x[ p ],  y[ p ] ) ) return false;
          // Objects and Arrays must be tested recursively
      }
    
      for ( p in y )
        if ( y.hasOwnProperty( p ) && ! x.hasOwnProperty( p ) )
          return false;
            // allows x[ p ] to be set to undefined
    
      return true;
    },
    successToast: (msg, duration) => {
        toast.open({
          duration: duration,
          message: msg,
          position: "is-bottom-right",
          type: "is-success",
          hasIcon: false,
        });
      },
      errorToast: (msg, duration) => {
        toast.open({
          duration: duration,
          message: msg,
          position: "is-bottom-right",
          type: "is-danger",
          hasIcon: false,
        });
      },
      customDateFormatter(date) {
        if (date) {
          return date.getDate().toString().padStart(2, '0') + '/' + (date.getMonth() + 1).toString().padStart(2, '0') + '/' + date.getFullYear()
        }
        return ''
      },
      customDateParser(date) {
        if (date) {
          const s = date.split('.')
          if (s.length === 3) {
            return new Date(parseInt(s[2], 10), parseInt(s[1], 10) - 1, parseInt(s[0], 10), 0, 0, 0, 0)
          }
          return null
        }
        return null
      },
      dateString(date) {
        if (date == null) {
          return "select date";
        } else {
          const dstring = this.convertDateObject(date);
          return dstring[0] + "/" + dstring[1] + "/" + dstring[2];
        }
    },
      convertDateObject: (date) => {
        const dateStrings = [];
        if (date != null) {
          var dd = String(date.getDate()).padStart(2, "0");
          var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
          var yyyy = date.getFullYear();
    
          dateStrings.push(dd);
          dateStrings.push(mm);
          dateStrings.push(yyyy);
        }
        return dateStrings;
      },
    groupByKey: (arr, key) => {
        let outputObj = {}
        arr.forEach((obj)=> {
            if (obj[key] in outputObj){
                outputObj[obj[key]].push(obj)
            }else{
                outputObj[obj[key]] = [obj]
            }
        })
        return outputObj
    },
    binWithEqualRanges: (dataX, dataY, bucketCount) => {
        var i = 0, l = dataX.length;
        // If min and max are given, set them to the highest and lowest dataX values
        if (typeof min === 'undefined') {
            min = Infinity;
            max = -Infinity;
            for (i = 0; i < l; i++) {
                if (dataX[i] < min) min = dataX[i];
                if (dataX[i] > max) max = dataX[i];
            }
        }
        var inc = (max - min) / bucketCount,
            buckets = new Array(bucketCount);
        // Initialize buckets
        for (i = 0; i < bucketCount; i++) {
            buckets[i] = [];
        }
        // Put the numbers into buckets
        for (i = 0; i < l; i++) {
            // Buckets include the lower bound but not the higher bound, except the top bucket
            if (dataX[i] === max) buckets[bucketCount-1].push([dataX[i], dataY[i]]);
            else buckets[((dataX[i] - min) / inc) | 0].push([dataX[i], dataY[i]]);
        }
        return buckets;
    },
    binWithEqualBins: (dataX, dataY, bucketCount) =>{
        let data = dataX.map((el, idx)=>[el, dataY[idx]]) // join x and y data
        var i = 0,
            inc = (dataX.length / bucketCount) | 0,
            buckets = new Array(bucketCount),
            agg = new Array(bucketCount);
        data.sort(function(a, b) {
            return a[0] - b[0]; //sort in ascending order by x value
        });
        for (i = 0; i < bucketCount; i++) {
            var subset = data.slice(i*inc, (i+1)*inc);
            buckets[i] = subset;
            var sumX = 0, sumY=0, bl = subset.length;
            for (var j = 0; j < bl; j++) {
                sumX += subset[j][0]*subset[j][1] //to compute volume-weighted bin average
                sumY += subset[j][1]
            }
            agg[i] = [sumX /sumY, sumY]
        }
        return {x: agg.map(el=>el[0]), y: agg.map(el=>el[1])};
    },
    formatBinnedRates: (binData) => {
        let result = binnedData.map(bin => {
            let b = bin.reduce(((acc, xyArr) => {acc[0]+=xyArr[0]; acc[1]+=xyArr[1]; return acc}), [0,0])
            return [b[0]/bin.length, b[1]] //take average rate of bin and sum of volume in bin
        })
        return {x: result.map(el=>el[0]), y: result.map(el=>el[1])}
    },
    generateColourPalette: (len, n=3)=>{
        let colours = []
        let baseColours = ['rgba(53,73,94,', 'rgba(238,64,53,', 'rgba(243,119,54,', 'rgba(246,174,45,', 'rgba(123,192,67,', 'rgba(3,146,207,'] //root colours
        len = Math.max(len, 1)
        n = Math.min(n, len)
    
        for (let i = 0; i < len; i++) {
            let baseColIdx = Math.floor(i/n)
            let alpha = 1-((i/n) - baseColIdx)
            colours.push(baseColours[Math.min(baseColIdx, baseColours.length-1)]+`${alpha})`)
        }
        return colours
      },
    getKeyByValue: (object, value) => (Object.keys(object).find(key => object[key] === value)),
    displayDate(d, full=true, raw=false){
        let dt = new Date(d)
        if(dt){
            if (raw){
                return dt
            }
            if(full){
                return dt.toLocaleString()
            }
            return dt.toLocaleDateString()
        }
        return ''
    },
    sortData(data, key){
        if(!data){
          return data
        }
        return data.sort((a, b) => (a[key] > b[key]) ? 1 : -1)
      },
      safeParse(data){
        try{
          return JSON.parse(data)
        }catch(SyntaxError){
            return null
        }
      },
    uid(){
        return Date.now().toString(36) + Math.random().toString(36).substr(8)
    }
}



export {helpers}