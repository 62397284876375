<template>
  <div class="notification is-white has-text-left" style="margin-bottom: 10px; border: 1px solid rgba(163, 166, 168, 0.2);">
    <div class="columns">
      <div class="column is-11">
        <p class="heading" :style="pdfMode ? 'font-size:15px':''"><span class="has-text-weight-bold">{{obj.code}}</span> - {{obj.broad_code}}</p>
        <p class="subtitle" :class="pdfMode ? 'is-5':'is-6'" >{{obj.description}}</p>
      </div>
      <div v-if="showButton" class="column has-text-right">
        <b-button
        class="is-danger"
        icon-left="times"
        rounded
        outlined
        size="is-small"
        @click="destroy"
      ></b-button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ICD10Card",
  props: {
    obj: Object,
    showButton: {
      default: true, 
      type: Boolean
    },
    pdfMode: {
      default: false, 
      type: Boolean
    }
  },
  methods: {
    destroy(){
      this.$emit("destroy", this.obj)
    }
  }

};
</script>