<template>
  <div class="has-text-left" style="padding: 10px 100px 0px 100px">
    <div class="columns">
      <div class="column">
        <p class="title is-4">ICD-10 Diagnosis Search</p>
        <p class="subtitle is-6" style="padding-top:10px">Search ICD-10 diagnoses by description or code. Select up to 6 complications and/or up to 6 comorbidities</p>
        <p v-if="ICD10DBLoading" class="subtitle is-6 font-weight-light">loading ICD10 database...</p>
        <b-table v-if="ICD10Data" :data="Object.values(ICD10Data)" paginated :per-page="11">
          <template slot-scope="props">
            <b-table-column label="Code" searchable sortable field="code">{{ props.row.code }}</b-table-column>
            <b-table-column label="Description" searchable sortable :width="400" field="description">{{ props.row.description }}</b-table-column>

            <b-table-column label="Broad Code" searchable sortable field="broad_code">{{ props.row.broad_code }}</b-table-column>

            <b-table-column label="Category" sortable field="category">
              <b-tag :class="props.row.category == 1 ? 'is-primary-alt' : 'is-primary'">{{ props.row.category == 1 ? "comorb" : "complication" }}</b-tag>
            </b-table-column>

            <b-table-column label="Select">
              <b-button class="is-success" :disabled="selectedICD10.includes(props.row)" icon-left="plus" rounded outlined size="is-small" @click="addICD10(props.row)"></b-button>
            </b-table-column>
          </template>
        </b-table>
      </div>
      <div v-if="selectedICD10.length > 0" class="column is-4 notification is-white" style="padding-top:30px; margin-left:20px">
        <!-- <p class="title is-4">Diagnoses for John Doe</p> -->
        <div v-if="selectedComplications.length > 0" id="complications" style="padding-bottom: 30px">
          <p class="title is-5">
            Complications <span class="subtitle has-text-weight-light is-7">({{ 6 - selectedComplications.length }} more allowed)</span>
          </p>
          <div v-for="(obj, i) in selectedComplications" :key="i">
            <ICD10Card :obj="obj" v-on:destroy="removeICD10"></ICD10Card>
          </div>
        </div>

        <div v-if="selectedComorbs.length > 0" id="comorbs">
          <p class="title is-5">
            Comorbidities <span class="subtitle has-text-weight-light is-7">({{ 6 - selectedComorbs.length }} more allowed)</span>
          </p>
          <div v-for="(obj, i) in selectedComorbs" :key="i">
            <ICD10Card :obj="obj" v-on:destroy="removeICD10"></ICD10Card>
          </div>
        </div>
      </div>
    </div>
    <div>
      <b-field grouped position="is-right">
        <p class="control">
          <b-button style="margin-top: 0px" @click="back" class="is-primary is-medium">back</b-button>
        </p>
        <p class="control">
          <b-button style="margin-top: 0px" @click="submit" class="is-primary is-medium">next</b-button>
        </p>
      </b-field>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ICD10Card from "./ICD10Card";

export default {
  name: "ICD10Section",
  components: {
    ICD10Card
  },
  props: {
    diagnoses: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      icd10Descr: "",
      selectedICD10: []
    };
  },
  computed: {
    ...mapGetters("dashboard", ["ICD10Data", "ICD10DBLoading", "ICD10Error", "patientICD10Diagnoses"]),
    selectedComplications() {
      return this.selectedICD10.filter(obj => obj.category == 2);
    },
    selectedComorbs() {
      return this.selectedICD10.filter(obj => obj.category == 1);
    }
  },

  mounted() {
    if (!this.ICD10Data) {
      this.loadICD10DB().catch(err => {
        console.log("Could not load ICD10 data: " + err);
      }).finally(()=>{
        if(this.patientICD10Diagnoses && this.patientICD10Diagnoses.length > 0){
          this.populateSelectedDiagnoses(this.patientICD10Diagnoses)
        }else if(this.diagnoses){
          this.populateSelectedDiagnoses(this.diagnoses); // populate from prop if not in store
        }else{
          this.populateSelectedDiagnoses([]); 
        }
      })
    }
    
  },
  watch: {
    // diagnoses(val) {
    //   this.populateSelectedDiagnoses(val);
    // }
    patientICD10Diagnoses(val){
      this.selectedICD10 = []
      if(val){
        this.populateSelectedDiagnoses(val)
      }
    }
  },
  methods: {
    ...mapActions("dashboard", ["loadICD10DB"]),
    populateSelectedDiagnoses(diagnoses) {
      if (diagnoses && diagnoses.length>0 && this.ICD10Data) {
        this.selectedICD10 = [];
        diagnoses.forEach(code => {
          if (code in this.ICD10Data) {
            this.selectedICD10.push(this.ICD10Data[code]);
          }
        });
      }
    },
    addICD10(data) {
      if (data && !this.selectedICD10.includes(data)) {
        this.selectedICD10.push(data);
      }
    },
    removeICD10(data) {
      const index = this.selectedICD10.indexOf(data);
      if (index > -1) {
        this.selectedICD10.splice(index, 1);
      }
    },
    submit() {
      this.$emit("submit", this.selectedICD10);
    },
    back() {
      this.$emit("navigateBack");
    }
  }
};
</script>
