import {ApiService, handleAxiosError} from './api.service'
import { TokenService } from './storage.service'

class AuthenticationError extends Error {
    constructor(errorCode, message) {
        super(message)
        this.name = this.constructor.name
        this.message = message
        this.errorCode = errorCode
    }
}

const UserService = {
    /**
     * Login the user and store the access token to TokenService. 
     * 
     * @returns access_token
     * @throws AuthenticationError 
    **/
    login: async function(email, password) {
        const requestData = {
            method: 'post',
            url: "user/login",
            headers: {'Content-Type': 'application/json'},
            data: JSON.stringify({
                email: email, 
                password: password
            })
        }
        try {
            const response = await ApiService.customRequest(requestData)
            TokenService.saveToken(response.data.access_token)
            TokenService.saveUser(email)
            TokenService.saveRefreshToken(response.data.refresh_token)
            ApiService.setHeader()
            return response.data
        } catch (error) {
            const errorObj = handleAxiosError(error)
            throw new AuthenticationError(errorObj.status, errorObj.data.detail || '')
        }
    },
    campaignLogin: async function(campaignNumber) {
        const requestData = {
            method: 'post',
            url: "/user/campaign_login",
            headers: {'Content-Type': 'application/json'},
            data: JSON.stringify({
                campaignNumber: campaignNumber, 
            })
        }
        try {
            const response = await ApiService.customRequest(requestData)
            TokenService.saveToken(response.data.access_token)
            TokenService.saveRefreshToken(response.data.refresh_token)
            ApiService.setHeader()
            return response.data
        } catch (error) {
            const errorObj = handleAxiosError(error)
            throw new AuthenticationError(errorObj.status, errorObj.data.detail || '')
        }
    },
    /**
     * Refresh the access token.
    **/
    refreshToken: async function() {
        const refreshToken = TokenService.getRefreshToken()

        const requestData = {
            method: 'post',
            url: "user/refresh_token",
            data: {
                grant_type: 'refresh_token',
                refresh_token: refreshToken
            }
        }

        try {
            const response = await ApiService.customRequest(requestData)

            if('new_access_token' in response.data){
                TokenService.saveToken(response.data.new_access_token)
            }else{
                console.log("Warning: did not update access token. Data received: "+response.data)
            }
            // Update the header in ApiService
            ApiService.setHeader()
        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data.detail)
        }
    },
    /**
     * Logout the current user by removing the token from storage. 
     * 
     * Will also remove `Authorization Bearer <token>` header from future requests.
    **/
    logout() {
        // Remove the token and remove Authorization header from Api Service as well 
        TokenService.removeToken()
        TokenService.removeUser()
        TokenService.removeRefreshToken()
        ApiService.removeHeader()
    }
}

export default UserService

export { UserService, AuthenticationError}