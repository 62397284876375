<template>
  <div>
    <navbar v-if="false"></navbar>
    <div
      class="has-text-left column"
      style="padding: 10px 100px 0px 100px"
      v-if="section != -1"
    >
      <div class="columns" style="margin-top: 20px">
        <div class="column is-2">
          <img src="/assets/r2w_logo.png" class="has-text-left" width="200px" />
        </div>

        <div class="column has-text-left is-5">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <div class="has-text-left" style="padding-top: 10px">
              <ul v-if="section > 2">
                <li :class="section == 0 ? 'is-active' : 'is-light'">
                  <a href="#" @click="section = 0">General Info</a>
                </li>
                <li
                  v-if="infoSectionData"
                  :class="section == 1 ? 'is-active' : 'is-light'"
                >
                  <a href="#" @click="section = 1">ICD-10 Diagnoses</a>
                </li>
                <li
                  v-if="icd10Data && infoSectionData"
                  :class="section == 2 ? 'is-active' : 'is-light'"
                >
                  <a href="#" @click="section = 2">Modifiers</a>
                </li>
              </ul>
            </div>
          </nav>
        </div>

        <div v-if="campaignData" class="column has-text-right">
          <p class="heading" style="margin-bottom:5px; margin-top:5px">campaign | {{campaignData.campaignNumber}}</p>
          <p class="title is-6">{{campaignData.label}}</p>
          <!-- <p class="subtitle is-6">{{campaignData.customer}}</p> -->
        </div>
        <div class="column" >
          <b-field grouped position="is-right">
            <p class="control">
          <b-button
            v-if="permissionTier <= 1"
            outlined
            rounded
            style="float:right"
            @click="$router.push('admin')"
            class="is-primary"
            :icon-left="section == -1 ? 'times' : 'cog'"
            >{{ section == -1 ? "escape" : "Admin Portal" }}</b-button>
            </p>
            <p class="control">
          <b-button outlined rounded @click="newCaseWithConfirm()" icon-left="file" class="is-primary" >new case</b-button>
            </p>
            <p class="control">
          <b-button outlined rounded @click="logout('Goodbye')" class="is-primary" >logout</b-button>
            </p>
        </b-field>
        </div>
      </div>
    </div>

    <div class="has-text-left" style="padding: 20px 0px 50px 0px">
      <div v-show="section == 0">
        <InfoSection v-on:submit="submitInfoSection"></InfoSection>
      </div>
      <div v-show="section == 1">
        <ICD10Section
          :diagnoses="populatedICD10"
          v-on:submit="submitICD10Section"
          v-on:navigateBack="transition(-1)"
        ></ICD10Section>
      </div>
      <div v-show="section == 2">
        <ModifierSection
          :data="summaryData"
          @submit="submitModifierSection"
          v-on:navigateBack="transition(-1)"
        ></ModifierSection>
      </div>
      <div v-show="section == 3">
        <SummarySection
          :data="summaryData"
          v-on:navigateBack="transition(-1)"
        ></SummarySection>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Navbar from "./Navbar";
import ICD10Section from "./ICD10Section";
import InfoSection from "./InfoSection";
import ModifierSection from "./ModifierSection";
import SummarySection from "./SummarySection";

export default {
  name: "Dashboard",
  components: {
    Navbar,
    ICD10Section,
    InfoSection,
    ModifierSection,
    SummarySection
  },
  data() {
    return {
      section: 0,
      prevSection: 0,
      infoSectionData: null,
      icd10Data: null,
      modifierData: null,
      populatedICD10: null
    };
  },
  computed: {
    ...mapGetters("auth", ["permissionTier", "username"]),
    ...mapGetters("dashboard", ["campaignData"]),
    summaryData() {
      let data = {
        ...this.infoSectionData,
        ...{ icd10: this.icd10Data },
        ...{ modifiers: this.modifierData },
      }; //merge Objects
      let campNum = null
      if(this.campaignData){
        campNum = this.campaignData.campaignNumber || null
      }
      data['campaignNumber'] = campNum
      return data
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    adminPortalTransition() {
      if (this.section != -1) {
        this.prevSection = this.section;
        this.section = -1;
      } else {
        this.section = this.prevSection;
      }
    },
    newCaseWithConfirm(){
      this.$buefy.dialog.confirm({
                    title: 'New Case Confirmation',
                    message: 'Are you sure you want to start a new case? \
                    You will lose any data that has now been saved. <br><br>\
                    To save data first, perform a prediction and follow the prompts to save thereafter.',
                    confirmText: 'confirm',
                    type: 'is-primary-alt',
                    hasIcon: true,
                    onConfirm: () => this.newCase()
                })
    },
    newCase(){
      this.$router.go(0)
      this.$router.go({ name: 'dashboard' })
    },
    submitInfoSection(data) {
      this.infoSectionData = data;
      this.transition(1);
    },
    submitICD10Section(data) {
      this.icd10Data = data;
      this.transition(1);
    },
    submitModifierSection(data) {
      this.modifierData = data;
      this.transition(1);
    },
    transition(dir) {
      this.section = Math.max(this.section + dir, 0);
    },
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  color: white;
  text-align: center;
}
</style>