<template>
  <div class="container" style="padding:20px">
    <div class="has-text-weight-light" ref="pdfContainer">
      <div class="columns">
        <div class="column is-6">
          <p class="title is-3 has-text-weight-bold">CareWorks R2W</p>
          <p class="subtitle is-5" style="margin-bottom:8px">Patient Report</p>
        </div>
        <div class="column is-6 has-text-right">
          <p class="subtitle is-5" style="margin-bottom:8px">{{ customDateFormatter(new Date()) }}</p>
          <p v-if="username" class="subtitle is-6">
            Generated by <span class="has-text-primary-alt">{{ username }}</span>
          </p>
        </div>
      </div>

      <div class="columns" >
        <div class="column is-6" style="margin-top:10px">
            <p class="title is-4">{{ data.name }} {{ data.lastName }}</p>
        </div>
        <div v-if="campaignData" class="column has-text-right">
          <p class="title is-5">Campaign {{ campaignData.campaignNumber }}</p>
          <p class="subtitle is-5">{{ campaignData.label }}</p>
        </div>
      </div>

      <div>
        <div class="columns is-multiline">
          <div class="column is-3" v-for="[label, val] of Object.entries(reportData)" :key="label">
            <div style="margin-top:20px">
              <p class="subtitle has-text-primary-alt is-5 has-text-weight-bold" style="margin-bottom:5px;">{{ label }}</p>
              <p class="subtitle has-font-weight-light is-5">{{ val }}</p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="comorbs.length > 0 || complications.length > 0" id="content" style="margin-top:40px">
        <p class="title is-4 has-text-weight-bold">Clinical Data</p>
        <div class="columns">
          <div v-if="comorbs.length > 0" class="column is-6">
            <p class="subtitle has-text-weight-bold is-4">Comorbidities</p>
            <div class="columns is-multiline">
              <div v-for="(obj, idx) in comorbs" :key="'comorb' + idx" class="column is-6">
                <div style="margin-top:20px">
                  <p class="heading has-text-primary is-5 has-text-weight-bold" style="margin-bottom:5px; font-size:15px">
                    {{ obj.code }} <span class="has-text-weight-light">- {{ obj.broad_code }} </span>
                  </p>
                  <p class="subtitle has-font-weight-light is-5">{{ obj.description }}</p>
                </div>
              </div>
            </div>
          </div>
          <div v-if="complications.length > 0" class="column is-6">
            <p class="subtitle is-4 has-text-weight-bold">Complications</p>
            <div class="columns is-multiline">
              <div v-for="(obj, idx) in complications" :key="'comp' + idx" class="column is-6">
                <div style="margin-top:20px">
                  <p class="heading has-text-primary is-5 has-text-weight-bold" style="margin-bottom:5px; font-size:15px">
                    {{ obj.code }} <span class="has-text-weight-light">- {{ obj.broad_code }} </span>
                  </p>
                  <p class="subtitle has-font-weight-light is-5">{{ obj.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>

      <div style="padding-top:30px; padding-bottom: 20px">
        <p class="title is-4 has-text-weight-bold">Modifiers</p>
        <div class="columns is-multiline">
          <div v-for="(obj, idx) in modifiers" :key="idx" class="column is-one-quarter">
            <div style="margin-top:20px">
              <p class="subtitle has-text-primary is-5 has-text-weight-bold" style="margin-bottom:5px;">{{ camelToWord(obj.modifier) }}</p>
              <p class="subtitle has-font-weight-light is-5">{{ obj.value }}</p>
            </div>
          </div>
        </div>
      </div>

      <div style="padding-top:20px">
        <p class="title is-4 has-text-weight-bold">Model Prediction</p>
        <p class="subtitle is-5" style="margin-top:10px"><span class="has-text-weight-bold">Prediction date:</span> {{ customDateFormatter(new Date()) }}</p>
        <p class="subtitle is-5" style="margin-top:5px">
          <span class="has-text-weight-bold"> Prediction outcome:</span> {{ prediction.prediction }}<span v-if="formattedPredConf > 1"> with {{ formattedPredConf.toFixed(1) }}% confidence</span>
        </p>
      </div>

      <div style="padding-top:40px">
        <p class="title is-4 has-text-weight-bold">Further Information</p>
        <p class="subtitle is-5" style="padding-top:20px"><span class="has-text-weight-bold" style="margin-right: 15px">Referred to CareWorks? </span> yes / no</p>
        <p class="subtitle is-5" style="padding-top:20px"><span class="has-text-weight-bold">Assessor signature: </span></p>
      </div>
    </div>

    <div style="padding-top:40px">
      <p v-if="!exportEnabled" class="subtitle is-6" style="margin-bottom:10px">PDF export is only enabled once data has been saved</p>
      <b-field grouped>
        <p class="control">
          <b-button style="margin-bottom:40px; margin-top:5px" :disabled="!exportEnabled" @click="savePDF" class="is-primary-alt is-medium">generate PDF</b-button>
        </p>
        <p class="control">
          <b-button style="margin-bottom:40px; margin-top:5px" :disabled="!exportEnabled" class="is-primary-alt is-medium">send to CareWorks</b-button>
        </p>
      </b-field>
    </div>

    <div class="columns">
      <div class="column is-6"></div>
      <div class="column is-6"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { jsPDF } from "jspdf";

export default {
  name: "Report",
  props: {
    data: Object,
    prediction: Object,
    exportEnabled: Boolean
  },
  components: {},
  data() {
    return {
      icd10cols: [
        {
          field: "code",
          label: "Code",
          width: "40",
          numeric: false
        },
        {
          field: "description",
          label: "Description"
        }
      ],
      dataMapper: [
        { field: "birthDate", label: "Date of Birth" },
        { field: "ref", label: "R2W Ref. No." },
        { field: "dateLastWorked", label: "Date Last Worked" },
        { field: "dateClaimAccepted", label: "Date Claim Accepted" },
        { field: "age", label: "Age" },
        { field: "sourceRefNo", label: "Source Ref. No." },
        { field: "monthsOffWork", label: "Months off Work" },
        { field: "referralDate", label: "Referral Date" },
        { field: "gender", label: "Sex" },
        { field: "benefitValue", label: "Benefit Value" },
        { field: "ageAtDateLastWorked", label: "Age at Date Last Worked" },
        { field: "sourceRequestEmail", label: "Source Contact" },
        { field: "monthsToReferral", label: "Months to Referral" }
      ],
      dateFields: ["referralDate", "birthDate", "dateLastWorked", "dateClaimAccepted"]
    };
  },
  computed: {
    ...mapGetters("auth", ["permissionTier", "username"]),
    ...mapGetters("dashboard", ["campaignData"]),
    formattedPredConf() {
      // map 50-100 to 0-100
      if (!this.prediction || !("confidence" in this.prediction)) {
        return null;
      }
      return 100 * (Math.max(this.prediction.confidence, 0.5) - 0.5) * 2;
    },
    comorbs() {
      if (!this.data || !("icd10" in this.data)) {
        return [];
      }
      return this.data.icd10.filter(obj => obj.category == 1);
    },
    complications() {
      if (!this.data || !("icd10" in this.data)) {
        return [];
      }
      return this.data.icd10.filter(obj => obj.category == 2);
    },
    modifiers() {
      var out = [];
      if (!this.data || !("modifiers" in this.data)) {
        return out;
      }
      var mods = { ...this.data.modifiers.categorical, ...this.data.modifiers.range };
      for (const [k, v] of Object.entries(mods)) {
        out.push({ modifier: k, value: v.value });
      }
      return out;
    },
    reportData() {
      var data = {};
      this.dataMapper.forEach(obj => {
        if (obj.field in this.data) {
          var val = this.data[obj.field];
          if (val instanceof Object && "value" in val) {
            val = val.value; // handle nested case
          }
          if (this.dateFields.indexOf(obj.field) > -1) {
            val = this.dateFmt(val);
          }
          if (["monthsToReferral", "monthsOffWork"].indexOf(obj.field) > -1) {
            val = Number(val.toFixed(1));
          }
          data[obj.label] = val;
        }
      });
      return data;
    }
  },
  methods: {
    back() {
      this.$emit("navigateBack");
    },
    camelToWord(str) {
      const result = str.replace(/([A-Z])/g, " $1");
      // converting first character to uppercase and join it to the final string
      var final = result.charAt(0).toUpperCase() + result.slice(1);
      final = final.replace("R T W", "R2W"); // hack because RTW gets separated otherwise
      final = final.replace("Rtw", "R2W");
      return final;
    },
    dateFmt(dateStr) {
      if (!dateStr) {
        return "";
      }
      if (dateStr instanceof String) {
        return this.customDateFormatter(new Date(dateStr));
      }
      return this.customDateFormatter(dateStr);
    },
    savePDF() {
      var doc = new jsPDF("p", "pt", [1500, 2000]);

      doc.html(this.$refs.pdfContainer, {
        callback: doc => doc.save(`R2W Report ${this.data.name} ${this.data.lastName}.pdf`),
        margin: [100, 20, 20, 20],
        x: 100,
        y: 100
      });

      // doc.save('R2W Report.pdf');
    }
  }
};
</script>
