import Vue from 'vue';
import Buefy from 'buefy';
import App from './App.vue';
import router from './router';
import store from './store';

import '../custom-styles.scss';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {ApiService} from './services/api.service'
import {TokenService} from './services/storage.service'

import {helpers} from "./utils/helpers.js"


library.add(fas)
Vue.component('fa-icon', FontAwesomeIcon)

Vue.config.productionTip = false;

Vue.use(Buefy, {defaultIconComponent: 'fa-icon',
defaultIconPack: 'fas',});

Vue.use(require('vue-cookies'))
Vue.$cookies.config('1d')

Vue.mixin({
  methods: helpers // register helper functions as mxiin that will be avail to all components through this.<method>
})

//const baseURI = process.env.VUE_APP_BACKEND_URL

ApiService.init('/api')

if(TokenService.getToken()){
  ApiService.setHeader()
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');