<template>
  <div class="container has-text-left" style="padding: 20px 0px 30px 0px">
    <div class="columns">
      <div class="column is-2">
        <img src="/assets/r2w_logo.png" class="has-text-left" width="200px" />
      </div>
      <div class="column has-text-left is-5"></div>
      <div class="column">
        <b-field style="float:right" grouped is-grouped-right>
          <p class="control">
            <b-button style="margin-top: 20px" outlined rounded @click="$router.push('dashboard')" class="is-primary">home</b-button>
          </p>
          <p class="control">
            <b-button style="margin-top: 20px" outlined rounded @click="logout('Goodbye.')" class="is-primary">logout</b-button>
          </p>
        </b-field>
      </div>
    </div>
    <b-tabs v-model="activeTab">
      <b-tab-item label="Patient Records">

        <div v-if="!showPatientDataExport" class="columns">
          <div class="column is-7">
            <p style="padding-top: 20px" class="title is-4">
              Patient Record Search
              <span class="button is-rounded is-small is-6 is-primary-alt" style="margin-left: 15px" @click="showPatientDataExport=true">show data export</span>
            </p>
            <p class="subtitle is-6">
              Search for patient information, diagnosis history and modifier history by reference number
            </p>
          </div>
          <div class="column">
            <b-field style="margin-top: 25px">
              <b-select v-model="searchQueryType">
                <option value="lastName">last name</option>
                <option value="sourceRef">source ref</option>
                <option value="ref">R2W ref</option>
              </b-select>
              <b-input expanded type="search" v-model="searchQuery" icon="search"> </b-input>
              <p class="control">
                <button :disabled="searchQuery.length == 0 || patientRecordsLoading" :loading="patientRecordsLoading" class="button is-primary" @click="getPatientRecord(searchQuery)">
                  {{ patientRecordsLoading ? "loading..." : "search" }}
                </button>
              </p>
            </b-field>
          </div>
        </div>

        <div v-else class="columns">
          <div class="column is-11">
            <p style="padding-top: 20px; margin-bottom:30x" class="title is-4">
              Data Export
              <span class="button is-rounded is-small is-6 is-primary-alt" style="margin-left: 15px" @click="showPatientDataExport=false">show patient search</span>
            </p>
            <p class="subtitle is-6">
              Search for all patient information grouped by a campaign. The source email address of a particular assessor can optionally be supplied for further filtering.
            </p>
            <b-field style="padding-top: 15px" grouped>
              <b-field label="campaign number" label-position="on-border" >
                <b-input type="search" v-model="searchQuery"> </b-input>
              </b-field>
              
              <b-field label="source email" label-position="on-border" style="width: 400px" message="optional: assessor's email address">
                <b-input expanded type="search" v-model="searchQueryEmail"> </b-input>
              </b-field>
              <p class="control">
                <button :disabled="searchQuery.length == 0 || patientRecordsLoading" :loading="patientRecordsLoading" class="button is-primary" @click="getCampaignData(searchQuery)">
                  {{ patientRecordsLoading ? "loading..." : "search" }}
                </button>
              </p>
            </b-field>

            <div v-if="campaignExportData" style="padding-top:20px">
              <p class="title is-4" style="margin-bottom:10px">Results</p>
              <p ><span class="has-text-primary-alt has-text-weight-bold">{{campaignExportData.length}}</span> matching records found</p>
              <div class="buttons" style="margin-top: 20px">
                <button class="button is-primary" @click="dumpCampaignData()">export</button>
                <button class="button is-light" @click="campaignExportData=null; searchQuery=''; searchQueryEmail=''">clear</button>
              </div>
              
            </div>
          </div>
          <div class="column">
         


          </div>
        </div>

        <div>
          <b-loading :is-full-page="false" v-model="patientRecordsLoading" :can-cancel="false"></b-loading>
        </div>
        <div v-if="allPatientRecords.length > 1 && !patientRecordsLoading">
          <div class="columns">
            <div class="column is-7">
              <b-field type="is-primary-alt">
                <b-select placeholder="Select a result" v-model="patientRecord">
                  <option v-for="record in allPatientRecords" :value="record" :key="record.ref"> {{ record.ref }} - {{ record.first_name }} {{ record.last_name }} </option>
                </b-select>
              </b-field>
              <p class="heading has-text-primary-alt">{{ allPatientRecords.length }} records found for "{{ searchQuery }}"</p>
            </div>
            <div class="column"></div>
          </div>
        </div>
        <div v-if="patientRecord && !patientRecordsLoading" style="padding: 30px 0px 30px 0px">
          <div class="columns">
            <div class="column is-3">
              <p class="heading">patient record</p>
              <p class="title is-4" style="margin-top: 5px">{{ patientRecord.first_name }} {{ patientRecord.last_name }}</p>

              <div v-for="(col, idx1) in autoColumns" :key="idx1 + '-pr'">
                <b-field :label="col.title" label-position="on-border" style="padding-bottom: 20px">
                  <b-input v-model="patientRecord[col.field]" v-if="col.type == 'string'" :value="patientRecord[col.field]"></b-input>
                  <b-datepicker v-if="col.type == 'date'" v-model="patientRecord[col.field]" trap-focus> </b-datepicker>
                </b-field>
              </div>

              <div v-for="(col, idx2) in computedCols" :key="idx2 + '-i2'">
                <div v-if="patientRecord[col.field]">
                  <p class="title is-6">
                    {{ col.title }}
                  </p>
                  <p class="subtitle is-6" style="padding-bottom: 15px">
                    {{ patientRecord[col.field].value.toLocaleString() }}
                  </p>
                </div>
              </div>
            </div>
            <div class="column is-3">
              <div v-if="patientRecord" style="margin-left: 30px">
                <p class="heading is-4">Record of Diagnoses</p>
                <p class="title is-4">Clinical History</p>

                <div v-for="[timestamp, diagGroup] of Object.entries(patientDiagnoses)" :key="timestamp">
                  <div v-if="'diagnoses' in diagGroup">
                    <p style="margin-bottom: 5px" class="title is-6">
                      Referral on
                      {{ new Date(parseFloat(timestamp) * 1000).toLocaleDateString() }}
                    </p>
                    <b-collapse :open="false" position="is-bottom" style="padding-bottom: 20px">
                      <a class="has-text-primary-alt" slot="trigger" slot-scope="props">
                        {{ !props.open ? "show detail" : "hide" }}
                      </a>
                      <div v-for="(diag, idx) in diagGroup.diagnoses" :key="idx" style="padding-top: 15px; padding-bottom: 15px">
                        <p class="heading">
                          <span class="has-text-weight-bold">{{ diag.code }}</span>
                          - {{ diag.broad_code }}
                        </p>
                        <p class="subtitle is-6">{{ diag.description }}</p>
                      </div>
                    </b-collapse>
                  </div>
                </div>
              </div>
            </div>

            <div class="column is-3">
              <div v-if="patientRecord">
                <p class="heading is-4">Record of Modifier progression</p>
                <p class="title is-4">Modifier History</p>
                <div v-for="[ts, modGroup] of Object.entries(patientModifiers)" :key="ts">
                  <p style="margin-bottom: 5px" class="title is-6">
                    Capture on
                    {{ new Date(parseFloat(ts) * 1000).toLocaleDateString() }}
                  </p>
                  <b-collapse :open="false" position="is-bottom" style="padding-bottom: 20px">
                    <a class="has-text-primary-alt" slot="trigger" slot-scope="props">
                      {{ !props.open ? "show detail" : "hide" }}
                    </a>
                    <div v-for="[field, obj] in Object.entries(modGroup.categorical)" :key="field" style="padding-top: 5px; padding-bottom: 10px">
                      <p class="heading">{{ field }}</p>
                      <b-tag>{{ obj.value }}</b-tag>
                      <!-- <p class="subtitle is-7" style="margin-top: 5px">
                        score: {{ obj.score }}
                      </p> -->
                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>

            <div class="column is-3">
              <div v-if="patientRecord">
                <p class="heading is-4">Record of predictions over time</p>
                <p class="title is-4">Prediction History</p>
                <div v-for="[ts, predObj] of Object.entries(patientPredictions)" :key="ts">
                  <p style="margin-bottom: 5px" class="title is-6">
                    Capture on
                    {{ new Date(parseFloat(ts) * 1000).toLocaleDateString() }}
                  </p>
                  <b-collapse :open="true" position="is-bottom" style="padding-bottom: 20px">
                    <a class="has-text-primary-alt" slot="trigger" slot-scope="props">
                      {{ !props.open ? "show detail" : "hide" }}
                    </a>
                    <div style="padding:5px 0px 5px 0px">
                      <p class="subtitle is-6">
                        <b-tag :class="predObj.prediction == 'RTW' ? 'is-success' : 'is-danger'">{{ predObj.prediction }} </b-tag> with {{ predObj.confidence }}% confidence
                      </p>
                    </div>
                  </b-collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab-item>

      <b-tab-item label="Modifier Editor">
        <div class="columns" style="padding-top: 20px">
          <div class="column is-3">
            <p class="title is-4">
              Modifier Editor
            </p>
            <b-field v-if="modifierData" label="Select modifier">
              <b-select placeholder="modifier name" v-model="selectedMod">
                <option v-for="(mod, i) in modifierData.categorical" :value="mod" :key="'mod-' + i">
                  {{ mod.label }}
                </option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-9">
            <div v-if="selectedMod" style="padding-top: 0px">
              <div class="columns">
                <div class="column is-8">
                  <p class="title is-4 has-text-primary" style="margin-bottom: 30px">
                    {{ selectedMod.label }}
                  </p>
                  <p class="subtitle is-5 has-text-primary-alt">Definition</p>
                </div>

                <div class="column has-text-right">
                  <b-button class="is-primary-alt" :disabled="!valuesChanged || updateLoading" :loading="updateLoading" @click="update">update</b-button>
                </div>
              </div>

              <div v-for="(col, j) in modCols" :key="'mod2-' + j">
                <b-field :label="col.label" label-position="on-border" style="padding-bottom: 20px">
                  <b-input @input="scoreChange" :type="col.type" :value="selectedMod[col.field]" v-model="selectedMod[col.field]"></b-input>
                </b-field>
              </div>

              <div class="columns">
                <div class="column is-10">
                  <p class="subtitle is-5 has-text-primary-alt" style="margin-top: 10px">
                    Option Scores
                  </p>
                </div>
                <div class="column is-2"></div>
              </div>

              <div v-for="(op, k) in selectedMod.options" :key="'sel-mod-' + k">
                <div class="columns">
                  <div class="column is-7">
                    <b-field :label="'option ' + (k + 1)" label-position="on-border" style="padding-bottom: 20px">
                      <b-input expanded @input="scoreChange" v-model="op.name" :value="op.name"></b-input>
                      <p class="control" v-if="showDelete" @click="deleteOption(op)">
                        <span class="button is-primary-alt">delete</span>
                      </p>
                    </b-field>
                  </div>

                  <div class="column is-4">
                    <b-field>
                      <b-slider
                        @dragend="scoreChange"
                        indicator
                        v-model="op.score"
                        rounded
                        tooltip-always
                        :class="op.score < 0.5 ? 'is-primary-alt' : 'is-primary'"
                        :tooltip="true"
                        format="raw"
                        :step="0.05"
                        :min="0"
                        :max="1"
                        locale="en-GB"
                      ></b-slider>
                    </b-field>
                  </div>
                  <div class="column is-1">
                    <p class="subtitle is-6 has-text-right" style="margin-top: 10px">
                      {{ op.score }}
                    </p>
                  </div>
                </div>
              </div>

              <b-field grouped position="is-left">
                <p class="control">
                  <b-button @click="addOption" class="is-primary" outlined style="margin-top: 20px">add option</b-button>
                </p>
                <p class="control">
                  <b-button class="is-primary-alt" style="margin-top: 20px" :outlined="!showDelete" @click="showDelete = !showDelete" icon-left="trash"></b-button>
                </p>
              </b-field>
            </div>
          </div>
        </div>
      </b-tab-item>
      <b-tab-item label="Campaign Editor">
        <campaign-editor></campaign-editor>
      </b-tab-item>
      <b-tab-item label="ICD10 Editor">
        <ICD10Editor></ICD10Editor>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { QueryService } from "@/services/query.service";
import CampaignEditor from "./CampaignEditor.vue";
import ICD10Editor from "@/components/admin/ICD10Editor.vue";

export default {
  name: "AdminPanel",
  components: {
    CampaignEditor,
    ICD10Editor
  },
  data() {
    return {
      activeTab: 0,
      patientRecord: null, // selected record
      allPatientRecords: [], // all records returned from query of which patientRecord should be one if not null
      searchQueryType: "lastName",
      patientRecordsLoading: false,
      updateLoading: false,
      modifierData: null,
      selectedMod: null,
      showDelete: false,
      valuesChanged: false,
      defaultOpenedDetails: [1],
      showPatientDataExport: false,
      campaignExportData: null,
      searchQuery: "",
      searchQueryEmail: "",
      modCols: [
        { field: "label", label: "Label", editable: true, type: "text" },
        {
          field: "description",
          label: "Description",
          editable: true,
          type: "textarea"
        }
        // {field: "options", label: "Options", editable: true, type: "text"},
        // { field: "weight", label: "Weight", editable: true, type: "number" },
      ],
      autoColumns: [
        {
          title: "First Names",
          field: "first_name",
          editable: true,
          type: "string"
        },
        {
          title: "Last Name",
          field: "last_name",
          editable: true,
          searchable: true,
          type: "string"
        },
        { title: "ID Number", field: "id_num", editable: true, type: "string" },
        { title: "DoB", field: "birth_date", editable: true, type: "date" },
        { title: "Sex", field: "gender", editable: true, type: "string" }
      ],
      computedCols: [
        { title: "Months off Work", field: "months_off_work" },
        { title: "Age at Date Last Worked", field: "age_date_last_worked" },
        { title: "Months to Referral", field: "months_to_referral" }
      ]
    };
  },
  computed: {
    patientDiagnoses() {
      let out = {};
      if (!this.patientRecord || !("clinical_history" in this.patientRecord)) {
        return out;
      }
      this.patientRecord.clinical_history.forEach(obj => {
        if ("timestamp" in obj) {
          out[obj["timestamp"]] = obj;
        }
      });
      return out;
    },
    patientPredictions() {
      let out = {};
      if (!this.patientRecord || !("prediction_history" in this.patientRecord) || !("records" in this.patientRecord.prediction_history)) {
        return out;
      }
      this.patientRecord.prediction_history.records.forEach(obj => {
        obj = this.deepCopy(obj);
        if ("confidence" in obj && obj["confidence"] <= 1) {
          obj["confidence"] = (100 * (Math.max(obj.confidence, 0.5) - 0.5) * 2).toFixed(0);
        }
        if ("timestamp" in obj) {
          let ts = parseInt(Date.parse(obj["timestamp"]) / 1000);
          out[ts] = obj;
        }
      });
      return out;
    },
    patientModifiers() {
      let out = {};
      if (!this.patientRecord || !("modifier_history" in this.patientRecord)) {
        return out;
      }
      this.patientRecord.modifier_history.forEach(obj => {
        if ("timestamp" in obj && "modifiers" in obj) {
          out[obj["timestamp"]] = obj["modifiers"];
        }
      });
      return out;
    }
  },
  created() {
    // this.getPatientRecords()
    this.getModifierDefinitions();
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    deleteOption(option) {
      this.selectedMod.options = this.selectedMod.options.filter(op => {
        return op.name != option.name;
      });
      this.valuesChanged = true;
    },
    scoreChange(val) {
      this.valuesChanged = true;
    },
    addOption() {
      this.selectedMod.options.push({ name: "<option name>", score: 0.5 });
    },
    update() {
      this.updateLoading = true;
      QueryService.updateModifier(this.selectedMod)
        .then(res => {
          this.$buefy.toast.open({
            duration: 2000,
            message: `Data saved successfully`,
            position: "is-bottom-right",
            type: "is-success",
            hasIcon: false
          });
        })
        .catch(res => {
          let msg = "Failed to save data. Check console for messages.";
          if ("data" in res && "message" in res.data) {
            msg = "Failed to save data. Received error message " + res.data.message;
          }
          this.$buefy.toast.open({
            duration: 4000,
            message: msg,
            position: "is-bottom-right",
            type: "is-danger",
            hasIcon: false
          });
        })
        .finally(() => (this.updateLoading = false));
    },
    getCampaignData(campNum = null, sourceEmail = null) {
      this.patientRecordsLoading = true;
      this.campaignExportData = null;

      if(!campNum){
        campNum = this.searchQuery
      }
      if(!sourceEmail && this.searchQueryEmail.length > 0){
        sourceEmail = this.searchQueryEmail
      }
      QueryService.loadCampaignExportData(campNum, sourceEmail)
        .then(res => {
          if (Object.keys(res.data).length == 0) {
            this.recordNotFound();
            return;
          }
          this.campaignExportData = res.data;
        })
        .finally(() => (this.patientRecordsLoading = false));
    },
    dumpCampaignData() {
      if (this.campaignExportData) {
        this.downloadObjectAsJson(this.campaignExportData, `R2W_campaign_export_${this.searchQuery}`);
      }
    },
    getPatientRecord(query) {
      this.patientRecordsLoading = true;
      let queryObj = { type: this.searchQueryType, value: query };
      QueryService.loadPatientRecords(queryObj)
        .then(res => {
          if (Object.keys(res.data).length == 0) {
            this.patientRecord = null;
            this.recordNotFound();
            return;
          }
          this.allPatientRecords = res.data;
          this.allPatientRecords.forEach(result => {
            this.autoColumns.forEach(obj => {
              if (obj.type == "date") {
                result[obj.field] = new Date(Date.parse(result[obj.field]));
              }
            });
          });
          this.patientRecord = this.allPatientRecords[0];
        })
        .catch(res => {
          this.patientRecord = null;
        })
        .finally(() => (this.patientRecordsLoading = false));
    },
    recordNotFound() {
      this.$buefy.toast.open({
        duration: 2500,
        message: `Couldn't find a record matching the supplied reference :(`,
        position: "is-top",
        type: "is-danger"
      });
    },
    getModifierDefinitions() {
      QueryService.loadModifiers()
        .then(res => {
          this.modifierData = res.data;
        })
        .catch(res => console.log("Error retrieving modifier data"));
    },
    submit() {
      this.$emit("submit", this.selectedICD10);
    },
    back() {
      this.$emit("navigateBack");
    }
  }
};
</script>
