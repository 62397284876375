<template>
  <div id="main" class="container has-text-left" style="padding-top: 20px; padding-bottom: 20px">
    <div v-if="campaignData" class="columns" style="padding-bottom: 5px">
      <div class="column is-5">
        <p style="padding-top: 20px" class="title is-4 has-text-primary">
          Patient Record Search
        </p>
        <p class="subtitle is-6">
          Locate and load historical patient records
        </p>
      </div>
      <div class="column is-6">
        <b-field style="margin-top: 25px">
          <b-select v-model="searchQueryType">
            <option value="lastName">last name</option>
            <option value="ref">R2W ref</option>
            <option value="sourceRef">source ref</option>
            <option value="idNum">ID number</option>
          </b-select>
          <b-input expanded type="search" v-model="searchQuery" icon="search"> </b-input>
          <p class="control">
            <button :disabled="searchQuery.length == 0 || patientRecordsLoading" :loading="patientRecordsLoading" class="button is-primary" @click="getPatientRecord(searchQuery)">
              {{ patientRecordsLoading ? "loading..." : "search" }}
            </button>
          </p>
        </b-field>
      </div>
    </div>
    <div v-if="allPatientRecords.length > 1 && !patientRecordsLoading">
      <div class="columns">
        <div class="column is-7">
          <b-field type="is-primary-alt">
            <b-select placeholder="Select a result" v-model="patientRecord">
              <option v-for="record in allPatientRecords" :value="record" :key="record.ref"> {{ record.ref }} - {{ record.first_name }} {{ record.last_name }} </option>
            </b-select>
          </b-field>
          <p class="heading has-text-primary-alt">{{ allPatientRecords.length }} records found for "{{ searchQuery }}"</p>
        </div>
        <div class="column"></div>
      </div>
    </div>

    <div class="columns" style="padding-top: 20px">
      <div class="column is-11">
        <div>
          <div class="columns">
            <div class="column is-6">
              <p class="title is-4 has-text-primary">
                Basic Patient Information
                <b-tag v-if="patientInfoComplete" type="is-success">complete</b-tag>
              </p>
            </div>
            <div class="column is-6 has-text-right">
              <b-button class="is-primary is-small" :icon-left="showPatientInfo ? 'caret-up' : 'caret-down'" outlined rounded @click="showPatientInfo = !showPatientInfo"></b-button>
            </div>
          </div>
          <div v-show="showPatientInfo">
            <b-field grouped>
              <b-field label="First Names" expanded>
                <b-input v-model="firstName"></b-input>
              </b-field>
              <b-field label="Last Name" expanded>
                <b-input v-model="lastName"></b-input>
              </b-field>
            </b-field>

            <b-field grouped>
              <b-field :width="200" label="ID number">
                <b-input v-model="idNo" type="number" maxlength="13"></b-input>
              </b-field>

              <b-field expanded label="Date of Birth">
                <b-datepicker
                  :show-week-number="false"
                  placeholder="Select"
                  icon="calendar"
                  :date-formatter="customDateFormatter"
                  :date-parser="customDateParser"
                  :years-range="[-90, 50]"
                  :max-date="new Date()"
                  v-model="DoB"
                ></b-datepicker>
              </b-field>
              <b-field label="Sex">
                <b-select placeholder="select" v-model="gender">
                  <option value="male">Male</option>
                  <option vale="female">Female</option>
                </b-select>
              </b-field>
            </b-field>
          </div>
        </div>

        <div id="work-details" style="padding-top: 50px">
          <div class="columns">
            <div class="column is-6">
              <p class="title is-4 has-text-primary">
                Employment Details
                <b-tag v-if="employmentDetailsComplete" type="is-success">complete</b-tag>
              </p>
            </div>
            <div class="column is-6 has-text-right">
              <b-button class="is-primary is-small" :icon-left="showEmploymentDetails ? 'caret-up' : 'caret-down'" outlined rounded @click="showEmploymentDetails = !showEmploymentDetails"></b-button>
            </div>
          </div>

          <div v-show="showEmploymentDetails">
            <div v-if="campaignData">
              <b-field grouped>
                <b-field label="Campaign Number" expanded>
                  <b-input disabled :value="campaignData.campaignNumber"></b-input>
                </b-field>
                <b-field label="Campaign Name" expanded>
                  <b-input disabled :value="campaignData.label"></b-input>
                </b-field>
                <b-field label="Campaign Owner" expanded>
                  <b-input disabled :value="campaignData.customer"> </b-input>
                </b-field>
              </b-field>

              <b-field grouped>
                <b-field label="Default Contact" expanded>
                  <b-input disabled :value="campaignData.email"></b-input>
                </b-field>
                <b-field label="Start Date" expanded>
                  <b-input disabled :value="displayDate(campaignData.start_date, false)"> </b-input>
                </b-field>
                <b-field label="End Date" expanded>
                  <b-input disabled :value="displayDate(campaignData.end_date, false)"> </b-input>
                </b-field>
              </b-field>
            </div>

            <b-field grouped>
              <b-field message="employer's reference number for patient" label="Source Reference No." expanded>
                <b-input v-model="sourceRefNo"></b-input>
              </b-field>
              <b-field message="email address to send report to" label="Source Request Email Address" expanded>
                <b-input v-model="sourceReqEmail" type="email"></b-input>
              </b-field>
            </b-field>

            <b-field grouped>
              <b-field expanded label="Date Last Worked">
                <b-datepicker
                  :show-week-number="false"
                  placeholder="Select"
                  icon="calendar"
                  locale="en-GB"
                  :years-range="[-15, 15]"
                  :max-date="new Date()"
                  :date-formatter="customDateFormatter"
                  :date-parser="customDateParser"
                  v-model="lastWorkedDate"
                ></b-datepicker>
              </b-field>
              <b-field expanded label="Claim Registration Date">
                <b-datepicker
                  placeholder="Select"
                  icon="calendar"
                  locale="en-GB"
                  :years-range="[-15, 15]"
                  :max-date="new Date()"
                  :min-date="lastWorkedDate"
                  :date-formatter="customDateFormatter"
                  :date-parser="customDateParser"
                  v-model="claimRegDate"
                ></b-datepicker>
              </b-field>
            </b-field>

            <b-field grouped>
              <b-field label="Referral Date" message="usually today's date">
                <b-datepicker
                  :show-week-number="false"
                  icon="calendar"
                  locale="en-GB"
                  :years-range="[-15, 15]"
                  :min-date="claimRegDate"
                  :max-date="new Date()"
                  :date-formatter="customDateFormatter"
                  :date-parser="customDateParser"
                  v-model="referralDate"
                ></b-datepicker>
              </b-field>

              <b-field expanded message="value in ZAR" label="Benefit Value">
                <b-input v-model="benefitValue" type="number" min="0"> </b-input>
              </b-field>
            </b-field>
          </div>
        </div>
        <div class="has-text-right">
          <b-button style="margin-top: 50px" @click="submit" class="is-primary is-medium">next</b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import bands from "@/data/bands.json";
import { QueryService } from "@/services/query.service";

export default {
  name: "InfoSection",
  components: {},
  data() {
    return {
      bands,
      activeStep: 1,
      firstName: "",
      lastName: "",
      gender: null,
      DoB: null,
      idNo: null,
      lastWorkedDate: null,
      referralDate: new Date(),
      claimRegDate: null,
      sourceRefNo: "",
      sourceReqEmail: null,
      showPatientInfo: true,
      showEmploymentDetails: true,
      showWorkSpecifics: true,
      benefitValue: null,
      patientRecord: null, // selected record
      allPatientRecords: [], // all records returned from query of which patientRecord should be one if not null
      searchQueryType: "lastName",
      patientRecordsLoading: false,
      searchQuery: "",
      ref: null
    };
  },
  computed: {
    ...mapGetters("dashboard", ["campaignData"]),
    age() {
      return this.getAge(this.DoB);
    },
    ageAtDateLastWorked() {
      let ageLW = this.getAge(this.DoB, this.lastWorkedDate);
      return { value: ageLW, score: this.getBandScore("ageLastWorked", ageLW) };
    },
    timeOffWork() {
      if (this.referralDate && this.lastWorkedDate) {
        let monthsOff = (this.referralDate - this.lastWorkedDate) / (60 * 60 * 24 * 30 * 1000); // ! TODO: get more accurate number of days/month instead of 30
        return {
          value: monthsOff,
          score: this.getBandScore("timeOffWork", parseInt(monthsOff))
        };
      }
      return null;
    },
    monthsToReferral() {
      if (this.referralDate && this.claimRegDate) {
        let monthsOff = (this.referralDate - this.claimRegDate) / (60 * 60 * 24 * 30 * 1000); // ! TODO: get more accurate number of days/month instead of 30
        return {
          value: monthsOff,
          score: this.getBandScore("monthsToReferral", parseInt(monthsOff))
        };
      }
      return null;
    },
    patientInfoComplete() {
      let check = this.firstName.length > 0 && this.lastName.length > 0;
      check = check && this.gender && this.DoB && this.idNo.length == 13;
      return check;
    },
    employmentDetailsComplete() {
      let check = this.sourceRefNo && this.sourceRefNo.length > 0 && this.checkEmailValid(this.sourceReqEmail);
      check = check && this.lastWorkedDate && this.claimRegDate && this.benefitValue;
      return check;
    },
    nextEnabled() {
      return this.employmentDetailsComplete && this.patientInfoComplete;
    }
  },
  methods: {
    ...mapMutations("dashboard", ["setPatientInfo", "setPatientICD10Diagnoses", "setPatientModifiers", "setModifierData"]),
    getAge(startDate, endDate = null) {
      if (startDate) {
        if (!endDate) {
          endDate = this.referralDate;
        }
        let delta = endDate - startDate;
        return parseInt(delta / (60 * 60 * 24 * 365 * 1000));
      }
      return -1;
    },
    getBandScore(varName, baseValue) {
      let score = null;
      if (varName in this.bands && baseValue != null) {
        this.bands[varName].forEach(band => {
          if (baseValue >= band.bounds[0] && baseValue <= band.bounds[1]) {
            score = band.score;
            return;
          }
        });
      }
      return score; // either value out of range or variable name not defined
    },
    checkEmailValid(email) {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    submit() {
      let compiledData = this.compileData();
      if (compiledData) {
        this.$emit("submit", compiledData);
      }
    },
    validationChecks() {
      let errs = [];
      let warnings = [];
      if (this.referralDate && this.claimRegDate && this.lastWorkedDate) {
        if (this.referralDate < this.claimRegDate || this.referralDate < this.lastWorkedDate) {
          errs.push("Referral date should come after claim reg and last worked dates");
        }
        if (this.claimRegDate < this.lastWorkedDate) {
          errs.push("Claim registration date should come after last worked date");
        }
      } else {
        errs.push("Valid referral date, claim reg date and last worked date are required.");
      }
      if (!this.idNo || String(this.idNo).length != 13) {
        warnings.push("ID number not 13 characters");
      }
      return { errors: errs, warnings: warnings };
    },
    compileData() {
      let valChecks = this.validationChecks();
      if (valChecks["errors"].length > 0) {
        let msg = "<ul>";
        valChecks.errors.forEach(err => {
          msg += "<li> - " + err + "</li>";
        });
        msg += "</ul>";
        this.$buefy.toast.open({
          duration: 3000 * valChecks["errors"].length,
          message: msg,
          position: "is-bottom-right",
          type: "is-danger",
          hasIcon: false
        });
        return;
      }
      if (valChecks["warnings"].length > 0) {
        this.$buefy.toast.open({
          duration: 3000,
          message: valChecks["warnings"].join(" <br> "),
          position: "is-bottom-right",
          type: "is-warning",
          hasIcon: false
        });
      }
      return {
        ref: this.ref || this.uid().toUpperCase(),
        referralDate: this.referralDate,
        name: this.firstName,
        lastName: this.lastName,
        birthDate: this.DoB,
        age: this.age,
        idNo: this.idNo,
        gender: this.gender,
        sourceRefNo: this.sourceRefNo,
        sourceRequestEmail: this.sourceReqEmail,
        dateLastWorked: this.lastWorkedDate,
        ageAtDateLastWorked: this.ageAtDateLastWorked,
        dateClaimAccepted: this.claimRegDate,
        monthsOffWork: this.timeOffWork,
        monthsToReferral: this.monthsToReferral,
        benefitValue: this.benefitValue ? this.benefitValue : 0
      };
    },
    parseDate(s) {
      return new Date(Date.parse(s));
    },
    populateFromHistorical(data) {
      this.ref = data.ref;
      this.referralDate = this.parseDate(data.referral_date);
      this.lastWorkedDate = this.parseDate(data.date_last_worked);
      this.firstName = data.first_name;
      this.lastName = data.last_name;
      this.DoB = this.parseDate(data.birth_date);
      this.idNo = data.id_num;
      this.gender = data.gender;
      this.sourceRefNo = data.source_ref_num;
      this.sourceReqEmail = data.source_req_email;
      this.dateLastWorked = this.parseDate(data.date_last_worked);
      this.claimRegDate = this.parseDate(data.date_claim_accepted);
      this.benefitValue = data.benefit_value;
    },
    getPatientRecord(query) {
      this.patientRecordsLoading = true;
      this.allPatientRecords = [];
      let queryObj = { type: this.searchQueryType, value: query };
      QueryService.loadCampaignPatientRecords(queryObj)
        .then(res => {
          if (Object.keys(res.data).length == 0) {
            this.patientRecord = null;
            this.recordNotFound();
            return;
          }
          this.allPatientRecords = res.data;
          if (this.allPatientRecords && this.allPatientRecords.length == 1) {
            this.patientRecord = this.allPatientRecords[0];
          }
          // this.populateFromHistorical(this.patientRecord);
        })
        .catch(res => {
          this.patientRecord = null;
        })
        .finally(() => (this.patientRecordsLoading = false));
    },
    recordNotFound() {
      this.$buefy.toast.open({
        duration: 2500,
        message: `Couldn't find a record matching the supplied reference :(`,
        position: "is-top",
        type: "is-danger"
      });
    }
  },
  watch: {
    patientRecord(record) {
      // this changes when a new patient is selected from the list of patients matching the search query
      if (record) {
        this.populateFromHistorical(record);
        this.setPatientICD10Diagnoses(null);
        this.setPatientModifiers(null);
        console.log(record);
        if ("clinical_history" in record) {
          let clinHist = record["clinical_history"];
          if (clinHist) {
            if (clinHist.length == 0) {
              this.setPatientICD10Diagnoses([]);
            } else {
              let icd10Diagnoses = this.sortData(clinHist, "timestamp");
              icd10Diagnoses = icd10Diagnoses[clinHist.length - 1].icd_codes;
              this.setPatientICD10Diagnoses(icd10Diagnoses); // update icd10 data based on patient record
            }
          }
        }

        if ("modifier_history" in record) {
          let modHist = record["modifier_history"];
          if (modHist) {
            if (modHist.length == 0) {
              this.setPatientModifiers([])              
            }else{
            modHist = this.sortData(modHist, "timestamp");
            let modifiers = modHist[modHist.length - 1].modifiers;
            this.setPatientModifiers(modifiers) // update icd10 data based on patient record
            }

          }
        }
      }
    },
    idNo(val) {
      if (val && val.length > 6) {
        let id = String(val);
        let d = id.substring(0, 2) + "/" + id.substring(2, 4) + "/" + id.substring(4, 6);
        try {
          d = new Date(d);
          this.DoB = d;
        } catch {
          console.log("failed to parse date");
        }
      }
    },
    patientInfoComplete(val) {
      if (val) {
        setTimeout(() => {
          this.showPatientInfo = false;
          this.showEmploymentDetails = true;
        }, 2000);
      }
    },
    employmentDetailsComplete(val) {
      if (val) {
        setTimeout(() => {
          this.showEmploymentDetails = false;
        }, 2000);
      }
    }
  }
};
</script>

<style scoped></style>
