<template>
  <div class="notification is-white has-text-left" style="margin-bottom: 10px; border: 1px solid rgba(163, 166, 168, 0.2);">
    <div class="columns">
      <div class="column is-12">
        <p class="subtitle is-6 has-text-weight-bold">{{ obj.code }}</p>

        <div class="columns">
          <div class="column">
            <b-field label="Code" label-position="on-border">
              <b-input type="text" size="is-small" required v-model="obj.code"></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Broad Code" label-position="on-border">
              <b-input type="text" size="is-small" v-model="obj.broad_code"></b-input>
            </b-field>
          </div>
        </div>

        <div>
          <b-field label="Description" label-position="on-border">
            <b-input type="textarea" size="is-small" v-model="obj.description"></b-input>
          </b-field>
        </div>

        <div class="columns" style="padding-top: 20px">
          <div class="column is-6">
            <b-field label="Score" label-position="on-border">
              <b-input type="number" :min="0" :max="1" :step="0.05" size="is-small" :value="String(obj.score)" v-model="obj.score"></b-input>
            </b-field>
          </div>
          <div class="column" style="text-align: right">
            <a style="hover: click" @click="obj.category = obj.category == 1 ? 2 : 1"
              ><b-tag :class="obj.category == 1 ? 'is-primary-alt' : 'is-primary'">{{ obj.category == 1 ? "comorb" : "complication" }}</b-tag></a
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="showButton" class="buttons has-text-right">
      <b-button class="is-primary" icon-left="times" rounded outlined size="is-small" @click="destroy">cancel</b-button>

      <div v-if="createMode">
        <b-button :disabled="!updateBtnEnabled" class="is-success" icon-left="check" rounded outlined :loading="updatesLoading" size="is-small" @click="create"
        >create</b-button>
      </div>
      <div v-else>
        <b-button :disabled="!updateBtnEnabled" class="is-success" icon-left="check" rounded outlined :loading="updatesLoading" size="is-small" @click="update"
        >update</b-button>

      <b-button  :disabled="deleteLoading || updatesLoading" class="is-danger" icon-left="trash" rounded outlined :loading="deleteLoading" size="is-small" @click="confirmDelete">delete</b-button>
      </div>
      
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ICD10Card",
  props: {
    obj: Object,
    createMode: {
      default: false,
      type: Boolean
    },
    showButton: {
      default: true,
      type: Boolean
    },
    pdfMode: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      originalObj: {}
    };
  },
  computed: {
    ...mapGetters("dashboard", ["ICD10UpdatesLoading", "ICD10DeleteLoading"]),
    updateBtnEnabled(){
      let check = !(!this.objChanged || this.deleteLoading || this.updatesLoading)
      check &= this.obj.code.length > 0 && this.obj.description.length > 0
      check &= (0<= parseFloat(this.obj.score)) && (parseFloat(this.obj.score)<=1)
      return check
    },
    updatesLoading() {
      return this.ICD10UpdatesLoading.includes(this.obj.code);
    },
    deleteLoading() {
      return this.ICD10DeleteLoading.includes(this.obj.code);
    },
    objChanged() {
      return !this.compareObjects(this.originalObj, this.obj);
    }
  },
  mounted() {
    this.originalObj = this.deepCopy(this.obj);
  },
  methods: {
    ...mapActions("dashboard", ["updateICD10", "deleteICD10", "createICD10"]),
    destroy() {
      this.$emit("destroy", this.obj);
    },
    update() {
      this.updateICD10({ items: [this.obj] })
        .then(res => {
          this.successToast("update successful", 1000);
          this.$emit("destroy", this.obj);
        })
        .catch(err => {
          console.log(err);
        });
    },
    create() {
      let payload = this.deepCopy(this.obj)
      if('create' in payload){
        delete payload.create
      }
      this.createICD10({ item: payload })
        .then(res => {
          this.successToast("update successful", 1000);
          this.$emit("destroy", this.obj);
        })
        .catch(err => {
          console.log(err);
        });
    },
    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: "Deleting entry",
        message: "Are you sure you want to <b>delete</b> this item? This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteItem()
      });
    },
    deleteItem() {
      this.deleteICD10({ items: [this.obj] })
        .then(res => {
          this.successToast("item removed", 1000);
          this.$emit("destroy", this.obj);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>
