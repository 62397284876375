
import { UserService, AuthenticationError } from '../../services/user.service'
import {TokenService} from '../../services/storage.service'
import router from '../../router/index'
import { ToastProgrammatic as toast } from 'buefy'

const state =  {
    authenticating: false,
    accessToken: TokenService.getToken(),
    loggedIn: !!TokenService.getToken(),
    authenticationSuccess: false,
    authenticationErrorCode: 0,
    authenticationError: '',
    username: TokenService.getUser(),
    permissionTier: 3
}

const getters = {
    loggedIn: (state) => {
        return state.accessToken ? true : false
    },

    authenticationErrorCode: (state) => {
        return state.authenticationErrorCode
    },

    authenticationError(state){
        return state.authenticationError
    },

    authenticationSuccess(state){
        return state.authenticationSuccess
    },
    authenticating: (state) => {
        return state.authenticating
    },
    username: (state) => {
        return state.username
    },
    permissionTier: (state) => {
        return state.permissionTier
    }
}

const actions = {
    async login({ commit }, {email, password}) {
        commit('loginRequest');
        try {
            const loginData = await UserService.login(email, password);
            commit('loginSuccess', loginData)
            // Redirect the user to the page he first tried to visit or to the dash view
            router.push(router.history.current.query.redirect || '/dashboard');
        } catch (e) {

            commit('loginError', {errorCode: e.errorCode || 500, errorMessage: e.errorMessage || 'unknown error'})
            throw e
        }
    },
    async campaignLogin({ commit, dispatch}, {campaignNumber}) {
        commit('loginRequest');
        try {
            const loginData = await UserService.campaignLogin(campaignNumber);
            commit('dashboard/setCampaignData', loginData.campaign_data || null, {root: true})
            commit('campaignLoginSuccess', loginData)
            // Redirect the user to the page he first tried to visit or to the dash view
            router.push(router.history.current.query.redirect || '/dashboard');
        } catch (e) {
            commit('loginError', {errorCode: e.errorCode || 500, errorMessage: e.errorMessage || 'unknown error'})
            throw e
        }
    },

    logout({ commit }, message) {
        UserService.logout()
        commit('dashboard/clearData', null, {root: true})
        commit('logoutSuccess')
        if (router.currentRoute.meta.requiresAuth){
            router.push('/')
        }
        if(!message || message.length == 0){
            message = "Logged out sucessfully"
        }
        toast.open({
            duration: 2000,
            message: message,
            position: 'is-bottom-right',
        })
    }
}

const mutations = {
    loginRequest(state) {
        state.authenticating = true;
        state.authenticationError = ''
        state.authenticationErrorCode = 0
    },

    loginSuccess(state, loginData) {
        state.accessToken = loginData.access_token
        state.permissionTier = loginData.permission_tier
        state.username = loginData.email
        state.authenticationSuccess = true;
        state.authenticating = false;
    },
    campaignLoginSuccess(state, loginData) {
        state.accessToken = loginData.access_token
        state.permissionTier = 3 // this is a non-admin tier (lower has more privilege)
        state.authenticationSuccess = true;
        state.authenticating = false;
    },

    loginError(state, {errorCode, errorMessage}) {
        state.authenticating = false
        state.authenticationErrorCode = errorCode
        state.authenticationError = errorMessage
    },

    logoutSuccess(state) {
        state.accessToken = ''
        state.permissionTier = 36
        state.username = ''
    },
    updatePermissionTier(state, tier){
        state.permissionTier = tier
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}