<template>
  <div>
    <div
      class="has-text-left container"
      style="padding-right: 0px; padding-bottom: 150px; padding-left:0px;"
    >
      <b-field grouped>
        <p style="padding-top: 20px" class="title is-4">
              Campaign Management
        </p>
      </b-field>

      <div style="padding-bottom:15px">
        <b-field grouped group-multiline>
          <b-field>
            <b-button
              rounded
              size="is-small"
              icon-left="plus"
              @click="createNewCampaign()"
              >New Campaign</b-button
            >
          </b-field>
          <b-field>
            <b-button
              rounded
              size="is-small"
              icon-left="pen"
              :loading="isLoading"
              :disabled="isLoading"
              @click="editExistingCampaign()"
              >Edit Campaign</b-button
            >
          </b-field>
        </b-field>
      </div>

      <div v-if="editCampaign" style="padding-top:15px;">
        <div v-if="existingCampaigns.length == 0 && !isLoading">
          <p v-if="isLoading">Fetching campaigns...</p>
          <p v-else>There are no existing campaigns. Please create a new one.</p>
        </div>
        <div  v-if="existingCampaigns.length != 0" style="display:inline-flex; padding-bottom:20px">
          <p style="padding-top:5px; padding-right:15px;">Select a campaign</p>
        <b-field>
          <b-select
            size="is-small"
            @input="loadCampaign"
            v-model="activeCampaign"
          >
            <option v-for="option in existingCampaigns" :key="option">{{
              option
            }}</option>
          </b-select>
        </b-field>
        </div>
        
      </div>

      <form
        @submit.prevent="submit()"
        style="padding-top:15px;"
        :disabled="isLoading"
        v-if="showFields"
      >
        <div class="columns is-mobile">
          <div class="column">
            <b-field label="Customer" label-position="on-border" required>
              <b-input
                type="text"
                size="is-small"
                v-model="campaign.customer"
              ></b-input>
            </b-field>
            <b-field label="Campaign Label" label-position="on-border" required message="This is the label which will appear in the assessment. Try keep it short.">
              <b-input
                type="text"
                size="is-small"
                v-model="campaign.label"
              ></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Address 1" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                v-model="campaign.address1"
              ></b-input>
            </b-field>
            <b-field label="Address 2" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                v-model="campaign.address2"
              ></b-input>
            </b-field>
            <b-field label="Address 3" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                v-model="campaign.address3"
              ></b-input>
            </b-field>
            <b-field label="Postal Code" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                v-model="campaign.postalCode"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Contact Name" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                required
                v-model="campaign.contactName"
              ></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Designation" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                v-model="campaign.designation"
              ></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Email" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                required
                v-model="campaign.email"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <b-field label="Mobile Number" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                required
                v-model="campaign.mobileNumber"
              ></b-input>
            </b-field>
          </div>
          <div class="column">
            <b-field label="Work Number" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                v-model="campaign.workNumber"
              ></b-input>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-one-quarter">
            <b-field label="Start Date" label-position="on-border">
              <b-datepicker
                :mobile-native="false"
                position="is-bottom-right"
                size="is-small"
                :date-formatter="customDateFormatter"
                :date-parser="customDateParser"
                v-model="campaign.startDate"
              >
                <template v-slot:trigger>
                  <b-button
                    icon-right="calendar"
                    type="is-primary"
                    size="is-small"
                  ></b-button>
                </template>
              </b-datepicker>
            </b-field>
          </div>
          <div class="column is-one-quarter">
            <b-field label="End Date" label-position="on-border">
              <b-datepicker
                size="is-small"
                :mobile-native="false"
                position="is-bottom-right"
                :date-formatter="customDateFormatter"
                :date-parser="customDateParser"
                v-model="campaign.endDate"
              >
                <template v-slot:trigger>
                  <b-button
                    icon-right="calendar"
                    type="is-primary"
                    size="is-small"
                  ></b-button>
                </template>
              </b-datepicker>
            </b-field>
          </div>
        </div>
        <div class="columns">
          <div class="column is-2">
            <b-field label="Campaign Scope" label-position="on-border">
              <b-select size="is-small" required v-model="campaign.scope">
                <option v-for="option in scopeOptions" :key="option">{{
                  option
                }}</option>
              </b-select>
            </b-field>
          </div>
          <div class="column is-one-third">
            <b-field label="Campaign Number" label-position="on-border">
              <b-input
                type="text"
                size="is-small"
                v-model="campaign.campaignNumber"
                required
              ></b-input>
            </b-field>
       
          </div>
     <div class="column">
          <b-field
              label="Total Employees"
              label-position="on-border"
              required
            >
              <b-numberinput
                v-model="campaign.totalEmployees"
                controls-position="compact"
                size="is-small"
                step="1"
                :min="0"
              ></b-numberinput>
            </b-field>
          </div>
     
        </div>

     <div>
            <b-field
              label="Campaign Description"
              label-position="on-border"
              required
            >
              <b-input
                type="textarea"
                size="is-small"
                v-model="campaign.description"
              ></b-input>
            </b-field>
          </div>

          <b-field grouped group-multiline style="margin-top: 20px">
          <b-field>
                <b-button
            type="is-primary"
            size="is-small"
            native-type="submit"
            class="submitButton"
            :loading="isLoading"
            :disabled="isLoading"
            rounded
            >{{ buttonText }}</b-button>
          </b-field>
          <b-field>
       <b-button
              v-if="this.newCampaign"
              rounded
              outlined
              type="is-primary"
              size="is-small"
              icon-left="times"
              @click="clear()"
              >clear</b-button>
          </b-field>
        </b-field>

      </form>

      <b-loading
        :is-full-page="true"
        v-model="isLoading"
        :can-cancel="false"
      ></b-loading>
    </div>
  </div>
</template>

<script>
import { QueryService } from "@/services/query.service";
export default {
  name: "CampaignEditor",
  data() {
    return {
      isLoading: false,
      questionsEmitted: 0,
      activeCampaign: "",
      questionCount: 0,
      readyToSave: false,
      saveQuestion: false,
      editCampaign: false,
      newCampaign: false,
      showFields: false,
      existingCampaigns: [],
      scopeOptions: ["Whole Company", "Division", "Trial"],
      campaign: {
        customer: "",
        label:"",
        address1: "",
        address2: "",
        address3: "",
        postalCode: "",
        contactName: "",
        designation: "",
        email: "",
        mobileNumber: "",
        workNumber: "",
        startDate: null,
        endDate: null,
        scope: "",
        description: "",
        totalEmployees: null,
        campaignNumber: "",
      },
      questions: [],
    };
  },
  methods: {
    clear() {
      this.campaign = {
        customer: "",
        address1: "",
        address2: "",
        address3: "",
        postalCode: "",
        contactName: "",
        designation: "",
        email: "",
        mobileNumber: "",
        workNumber: "",
        startDate: null,
        endDate: null,
        scope: "",
        description: "",
        totalEmployees: null,
        campaignNumber: "",
      };
    },
    createNewCampaign() {
      this.showFields = true;
      this.newCampaign = true;
      this.editCampaign = false;
    },
    async editExistingCampaign() {
      if (this.showFields) {
        this.showFields = false;
      }
      this.isLoading = true
      this.newCampaign = false;
      this.questionCount = 0;
      this.editCampaign = true;
      try {
        var res = await QueryService.getCampaignNumbers();

        if (res.status == 200) {
          this.existingCampaigns = res.data.campaignNumbers;
        }
      } catch (error) {
        console.log(error);
        // this.errorToast(error.response.data.message, 4000);
      }
      this.isLoading = false
    },
    async loadCampaign(campaignNum) {
      this.isLoading = true;
      this.questionCount = 0;
      this.showFields = true;
      console.log(campaignNum);

      try {
        let res = await QueryService.getCampaign(campaignNum);
        var campObj = JSON.parse(res.data.campaign);
        if (campObj.startDate) {
          campObj.startDate = new Date(campObj.startDate.$date);
        }
        if (campObj.endDate) {
          campObj.endDate = new Date(campObj.endDate.$date);
        }
        this.campaign = campObj;
      } catch (error) {
        console.log(error);
      }
 
      this.isLoading = false;
    },

    submit() {
      this.readyToSave = true;
    },
    async saveToDB() {
      this.readyToSave = false;
      var res1 = null;
      this.isLoading = true;
      try {
        res1 = await QueryService.saveCampaign(this.campaign);
        this.successToast(res1.data.message, 3000);
        this.clear();
      } catch (error) {
        this.errorToast(error.response.data.message, 4000);
      }

      this.isLoading = false;
    },
    async updateCampaign() {
      this.isLoading = true;
      try {
        let res = await QueryService.updateCampaign(this.campaign);
        this.successToast(res.data.message, 3000);
      } catch (error) {
        console.log(error);
        this.errorToast(error.response.data.message, 4000);
        this.isLoading = false;
      }
      this.isLoading = false;
    },
    dateString(dateObj) {
      if (dateObj instanceof Date) {
        var dateArray = this.convertDateObject(dateObj);
        return dateArray[0] + "/" + dateArray[1] + "/" + dateArray[2];
      } else {
        return "";
      }
    },
  },
  computed: {
    buttonText() {
      if (this.newCampaign) {
        return "create campaign";
      }
      if (this.editCampaign) {
        return "save campaign";
      }
      return "";
    },
  },
  watch: {
    readyToSave: function() {
      if (this.readyToSave) {
        if (this.newCampaign) {
          this.saveToDB();
        }
        if (this.editCampaign) {
          this.updateCampaign();
        }
        this.readyToSave = false;
      }
    },
  },

};
</script>

<style scoped></style>
