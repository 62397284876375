<template>
  <div id="wrapper">
    <div id="main" class="container">
      <section class="hero is-fullheight has-text-left">
        <div class="hero-body">
          <div class="container">
            <div>
              <img src="/assets/r2w_logo.png" width="256px" />
            </div>
            <!-- <h1 class="title is-2">R2W</h1>
            <h2 class="subtitle is-5">Information Capture and R2W Prediction</h2> -->
            <div class="is-flex">
              <b-button style="margin-top: 10px; margin-left: 5px" v-if="!showLogin && !showCampaignLogin" outlined class="is-primary" @click="showLogin = true">user login</b-button>
              <b-button style="margin-top: 10px; margin-left: 10px" v-if="!showCampaignLogin && !showLogin" outlined class="is-primary" @click="showCampaignLogin = true">campaign login</b-button>
            </div>

            <form v-if="showLogin">
              <div class="columns" style="padding-top:20px">
                <div class="column is-4">
                  <form @submit.prevent="()=>{}">
                    <b-field class="has-text-left" label="Username" :type="{ 'is-danger': loginError }">
                      <b-input minlength="1" :disabled="authenticating" v-model="username"></b-input>
                    </b-field>

                    <b-field
                      label="Password"
                      class="has-text-left"
                      :type="{ 'is-danger': authenticationErrorCode == 401 }"
                      :message="{ 'Incorrect login credentials': authenticationErrorCode == 401 }"
                    >
                      <b-input :disabled="authenticating" type="password" v-model="password" password-reveal @keyup.native.enter="handleLogin()"></b-input>
                    </b-field>

                    <b-field grouped group-multiline>
                      <b-button :loading="authenticating" :disabled="authenticating || password.length==0" class="button field is-primary" @click="handleLogin()">
                        <span>submit</span>
                      </b-button>
                      <b-button class="button field is-light" @click="showLogin = false">
                        <span>back</span>
                      </b-button>
                    </b-field>
                  </form>
                </div>
              </div>
            </form>
            <form v-if="showCampaignLogin">
              <div class="columns" style="padding-top:20px">
                <div class="column is-4">
                  <form v-on:submit.prevent="()=>{}">
                    <b-field
                      label="Campaign Number"
                      class="has-text-left"
                      :type="{ 'is-danger': [401, 403].includes(authenticationErrorCode) }"
                      :message="{ 'Invalid campaign code': [401, 403].includes(authenticationErrorCode) }"
                    >
                      <b-input :disabled="authenticating" v-model="campaignNumber" @keyup.native.enter="handleLogin()"></b-input>
                    </b-field>

                    <b-field grouped group-multiline>
                      <b-button :loading="authenticating" :disabled="authenticating || campaignNumber.length==0" class="button field is-primary" @click="handleLogin()">
                        <span>submit</span>
                      </b-button>
                      <b-button class="button field is-light" @click="showCampaignLogin = false">
                        <span>back</span>
                      </b-button>
                    </b-field>
                  </form>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="has-text-centered">
          <p class="subtitle is-7" style="padding-bottom: 20px">Powered by <span class="has-text-primary">CareWorks</span></p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters, mapActions } from "vuex";

export default {
  name: "home",
  components: {},
  data() {
    return {
      showLogin: false,
      username: "",
      password: "",
      loginError: false,
      showCampaignLogin: false,
      campaignNumber: ""
    };
  },
  created() {},
  computed: {
    ...mapGetters("auth", ["loggedIn", "authenticating", "authenticationError", "authenticationErrorCode"])
  },
  methods: {
    ...mapActions("auth", ["login", "campaignLogin", "logout"]),
    async handleLogin() {
      // Perform a simple validation that email and password have been typed in
      if (this.showLogin) {
        if (this.username != "" && this.password != "") {
          this.login({ email: this.username, password: this.password }).catch(err => {
            console.log(err)
              if ([401, 403].includes(this.authenticationErrorCode)) {
                this.$buefy.toast.open({
                  duration: 2000,
                  message: "Invalid login credentials",
                  position: "is-top",
                  type: "is-danger"
                });
              }else{
                this.$buefy.toast.open({
                  duration: 2000,
                  message: "Error communicating with auth backend",
                  position: "is-top",
                  type: "is-danger"
                });
              }
          });
          this.password = "";
        }
      }
      else if (this.showCampaignLogin){
        // campaign login
        if(this.campaignNumber.length > 0){
          try {
            await this.campaignLogin({ campaignNumber: this.campaignNumber})
          }catch(err){
              if ([401, 403].includes(this.authenticationErrorCode)) {
                this.$buefy.toast.open({
                  duration: 2000,
                  message: "Invalid campaign code",
                  position: "is-top",
                  type: "is-danger"
                });
              }else{
                this.$buefy.toast.open({
                  duration: 2000,
                  message: "Error communicating with auth backend",
                  position: "is-top",
                  type: "is-danger"
                });
              }
          }
        }
      }
    }
  }
};
</script>

<style scoped>
/* #main{
  background: url("/assets/graphic.png") no-repeat right;
  background-size: 60%;
} */

#wrapper {
  height: 100%;
  background-image: url("/assets/back.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
