import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Dashboard from '../components/Dashboard.vue';
import AdminPanel from '@/components/admin/AdminPanel.vue';

import {TokenService} from '../services/storage.service'
import { ToastProgrammatic as toast } from 'buefy'

import store from '@/store'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta:{
      requiresAuth: true 
    }
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminPanel,
    meta:{
      requiresAuth: true,
      requiresAdmin: true 
    }
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});


router.beforeEach((to, from, next) => {
  const loggedIn = !!TokenService.getToken()

  if(to.name=='home' && loggedIn){
    next({name: 'dashboard'})
  }

  if(to.matched.some(record => record.meta.requiresAuth)){
    if(loggedIn){
      if(to.matched.some(record => record.meta.requiresAdmin)){
        console.log(store)
        if(store.getters['auth/permissionTier'] <= 1){
          next()
        }else{
          toast.open({type: 'is-warning', message: "You're not allowed there"})
          next({
            name: 'home'
          })
        }
      }
    }else{
      toast.open({type: 'is-danger', message: "You'll need to login first"})
      next({
        name: 'home'
      })
    }
  }
  
  next()
})

export default router;
