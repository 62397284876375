<template>
  <div class="has-text-left" style="padding: 10px 10px 0px 10px">
    <div class="columns">
      <div class="column">

        <div class="columns">
          <div class="column">
            <p class="title is-4">ICD-10 Editor</p>
        <p class="subtitle is-6" style="padding-top:10px">Add, update or remove ICD-10 entries</p>
          </div>

          <div class="column is-2 has-text-right">
            <b-button @click="createICD10" rounded outline class="is-primary" icon-left="plus">new</b-button>
          </div>
        </div>
        
        <p v-if="ICD10DBLoading" class="subtitle is-6 font-weight-light">loading ICD10 database...</p>
        <b-table v-if="ICD10Data" :data="Object.values(ICD10Data)" paginated :per-page="11">
          <template slot-scope="props">
            <b-table-column label="Code" searchable sortable field="code">{{ props.row.code }}</b-table-column>
            <b-table-column label="Description" searchable sortable :width="400" field="description">{{ props.row.description }}</b-table-column>

            <b-table-column label="Broad Code" searchable sortable field="broad_code">{{ props.row.broad_code }}</b-table-column>
            <b-table-column label="Score" sortable field="score">{{ props.row.score }}</b-table-column>

            <b-table-column label="Category" sortable field="category">
              <a @click='toggleCategory(props.row)'><b-tag  :class="props.row.category == 1 ? 'is-primary-alt' : 'is-primary'">{{ props.row.category == 1 ? "comorb" : "complication" }}</b-tag></a>
            </b-table-column>

            <b-table-column label="Edit">
              <b-button class="is-success" :disabled="selectedICD10.includes(props.row)" icon-left="edit" rounded outlined size="is-small" @click="addICD10(props.row)"></b-button>
            </b-table-column>
          </template>
        </b-table>
      </div>
      <div v-if="selectedICD10.length > 0" class="column is-4 notification is-white" style="padding-top:30px; margin-left:20px">
          <p class="title is-5">
            Edit area
          </p>
          <div v-for="(obj, i) in selectedICD10" :key="'item'+i">
            <ICD10EditCard :obj="obj" :createMode="obj.create || false" v-on:destroy="removeICD10"></ICD10EditCard>
          </div>
        </div>
    </div>
    <div>

    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ICD10EditCard from '@/components/admin/ICD10EditCard.vue';

export default {
  name: "ICD10Editor",
  components: {
      ICD10EditCard
  },
  data() {
    return {
        icd10Descr: "",
      selectedICD10: [],
      modifiedData: {}
    };
  },
  computed: {
    ...mapGetters("dashboard", ["ICD10Data", "ICD10DBLoading", "ICD10Error"])
  },
  methods: {
    ...mapActions("dashboard", ["loadICD10DB"]),
      addICD10(data) {
      if (data && !this.selectedICD10.includes(data)) {
        this.selectedICD10.push(data);
      }
    },
    removeICD10(data){
      const index = this.selectedICD10.indexOf(data);
      if (index > -1) {
        this.selectedICD10.splice(index, 1);
      }
    },
    createICD10(){
      this.selectedICD10.push({
        description: '',
        category: 1,
        score: 0.5,
        broad_code: '',
        code: '',
        create: true
      })
    }
  
  }, //end methods

  mounted() {
    if (!this.ICD10Data) {
      this.loadICD10DB().catch(err => {
        console.log("Could not load ICD10 data: " + err);
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
