import {ApiService} from '../services/api.service'

const QueryService = {
   getPrediction: async function(captureData) {
        let res = await ApiService.post(`/predict`, captureData)
        return res
    },
    loadICD10DB: async function(){
        return await ApiService.get('/data/icd10db')
    },
    updateICD10DB: async function(objects){
        return await ApiService.post('/data/icd10db/modify', objects)
    },
    addICD10DB: async function(object){
        return await ApiService.post('/data/icd10db/add', object)
    },
    deleteICD10DB: async function(objects){
        return await ApiService.post('/data/icd10db/delete', objects)
    },
    loadModifiers: async function(){
        return await ApiService.get('/data/modifiers')
    },
    loadPatientRecords: async function(queryObj){
        let apiMap = {ref: 'ref', sourceRef: 'source_ref_num', lastName: 'last_name'}
        let qstring = `/patient_history?`+apiMap[queryObj.type]+'='+queryObj.value
        return await ApiService.get(qstring)
    },
    loadCampaignPatientRecords: async function(queryObj){
        let apiMap = {ref: 'ref', sourceRef: 'source_ref_num', lastName: 'last_name', idNum:'id_num'}
        let qstring = `/campaign_patient_history?`+apiMap[queryObj.type]+'='+queryObj.value
        return await ApiService.get(qstring)
    },
    loadCampaignExportData: async function(campNum, sourceEmail){
        let qstring = `/all_campaign_patient_history?campaign_number=` + campNum
        if (sourceEmail){
            qstring += '&source_email='+sourceEmail
        }
        return await ApiService.get(qstring);
    },
    saveRecordToDB: async function(data){
        let qstring = `/save_record`
        return await ApiService.post(qstring, data)
    },
    updateModifier: async function(data){
        return await ApiService.put(`/update_modifier`, data)
    },
    updateCampaign: async function(campaign){
        let res = await ApiService.post("/campaign/update/", campaign)
        return res;
      },    
    saveCampaign: async function(campaignObj) {
        let res = await ApiService.post("/campaign/save/", campaignObj);
        return res;
    },
    getCampaignNumbers: async function() {
    let res = await ApiService.get("/campaign/campaign_numbers/");
    return res;
    },

    getCampaign: async function(code) {
    let res = await ApiService.get(`/campaign/data/?campaignNumber=${code}`);
    return res;
    },
}

export {QueryService}