<template>
  <div class="container">
    <div class="columns" v-if="!showReport">
      <div class="column is-9">
        <div class="card" style="margin-top: 30px">
          <div class="card-content">
            <div class="columns" style="padding: 20px 10px 0px 10px">
              <!-- <div class="column is-2">
                  <img width="64px" :src="`https://avatars.dicebear.com/api/initials/${'John Doe'}.svg?backgroundColors[]=blue&radius=50`">
            </div> -->
              <div class="column is-10">
                <p class="title is-4">{{ data.name }} {{ data.lastName }}</p>
                <p class="subtitle is-7">{{ data.ref }}</p>

                <b-collapse
                  :open="false"
                  position="is-bottom"
                  style="margin-top: 5px"
                >
                  <a
                    slot="trigger"
                    slot-scope="props"
                    class="has-text-primary-alt"
                  >
                    {{ !props.open ? "How does the R2W Model work?" : "Hide" }}
                  </a>
                  <p class="title is-6" style="margin-bottom: 5px">
                    Functionality
                  </p>
                  <p style="padding-top: 10px">
                    The
                    <span class="has-text-primary">CareWorks R2W Model</span>
                    generates a projected chance of returning to work. Lower
                    prediction probabilities generally correspond to increased
                    time and effort required to be spent with the client in
                    order to enable them to return to work.
                  </p>

                  <p
                    class="title is-6"
                    style="margin-bottom: 10px; margin-top: 20px"
                  >
                    Predictive Factors
                  </p>
                  <p>
                    It is well documented that age is one of the strongest
                    predictors of R2W. The
                    <span class="has-text-primary">CareWorks R2W Model</span>
                    enhances the prediction using clinical indicators of chronic
                    disease which significantly affect the feasibility of
                    returning to work. A third predictor is a dynamic component
                    which can tip the balance of R2W probability and is derived
                    from the claimant themselves, their caregivers, family,
                    doctors and access to healthcare. We call these “modifiers”
                    which may change the prediction probability over the course
                    of the client's intervention.
                  </p>
                </b-collapse>
              </div>
              <div class="column has-text-right">
                <b-button
                  @click="getPrediction"
                  :disabled="predictionLoading"
                  :loading="predictionLoading"
                  outlined
                  rounded
                  class="is-primary"
                  >predict</b-button
                >
              </div>
            </div>

            <div
              v-if="prediction && !predictionLoading"
              class="has-text-centered"
               style="padding-top: 40px"
            >
              <p class="heading">predicted outcome</p>
              <p class="title is-2">
                {{
                  prediction.prediction == "RTW"
                    ? "Return to Work"
                    : "Transfer Out"
                }}
              </p>
              <p class="subtitle is-4 has-text-primary-alt">
                {{ parseInt(formattedPredConf * 100) }}% probability
              </p>

                <img
                      src="/assets/r2w_spectrum.svg"
                      style="width:700px"
                  > 
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showReport" ref='pdfContainer'>
      <report :exportEnabled="savedRef != null"  :data="data" :prediction="prediction"></report>
    </div>

    <div
      v-if="showRawPredict"
      class="columns is-centered"
      style="padding-top: 50px"
    >
      <div class="column is-7">
        <div class="card">
          <div class="card-content">
            <pre>
            {{ prediction.raw_predictions }}
          </pre
            >
          </div>
        </div>
      </div>
    </div>

    <pre v-if="showData" style="padding-top: 50px">{{ data }}</pre>

    <b-field grouped position="is-left">
      <p class="control">
        <b-button
          v-if="permissionTier <= 2"
          style="margin-top: 10px"
          @click="showData = !showData"
          class="is-primary is-medium"
          >toggle data</b-button
        >
      </p>
      <p class="control">
        <b-button
          v-if="!showReport"
          style="margin-top: 10px"
          @click="back"
          class="is-primary is-medium"
          >back</b-button
        >
      </p>
      <p class="control">
        <b-button
          style="margin-top: 10px"
          :disabled="!prediction"
          @click="showReport = !showReport"
          class="is-primary is-medium"
          >{{showReport ? 'hide':'show'}} report</b-button
        >
      </p>
      <p class="control" v-if="prediction && 'raw_predictions' in prediction">
        <b-button
          v-if="permissionTier <= 2"
          style="margin-top: 10px"
          @click="showRawPredict = !showRawPredict"
          class="is-primary is-medium"
          >raw predictions</b-button
        >
      </p>
      <p class="control">
        <b-button
          style="margin-top: 10px"
          @click="saveToDB"
          :disabled="saveLoading"
          :loading="saveLoading"
          class="is-primary-alt is-medium"
          >save data</b-button
        >
      </p>
   
    </b-field>
  </div>
</template>

<script>
import { mapGetters} from "vuex";
import { QueryService } from "@/services/query.service";
import { jsPDF } from "jspdf";

import Report from "./Report"

export default {
  name: "SummarySection",
  props: {
    data: Object
  },
  components: {
    Report
  },
  data() {
    return {
      section: 0,
      showReport: false,
      predictionLoading: false,
      prediction: null,
      error: null,
      saveLoading: false,
      showData: false,
      showRawPredict: false,
      savedRef: null
    };
  },
  computed:{
    ...mapGetters('auth',['permissionTier']),

    formattedPredConf(){ // map 50-100 to 0-100
      if(!this.prediction || !('confidence' in this.prediction)){
        return null
      }
      return (Math.max(this.prediction.confidence, 0.5) - 0.5)*2
    }
  },
  methods: {
    back() {
      this.$emit("navigateBack");
    },

    savePDF(){
      var doc = new jsPDF('p', 'pt', 'A4');
      let renderHandlers = {
        '.pdfNoRender': (el, renderer) => true
      }
      doc.html(this.$refs.pdfContainer, {
        callback: (doc) => doc.save('R2W Report.pdf')
      });

    },
    saveToDB(){
      if(this.savedRef == this.data.ref){
        this.$buefy.dialog.confirm({
                    title: 'Are you sure?',
                    message: "You've already saved this user's data in this session. If you would like to add to this user's record, please confirm.",
                    confirmText: 'Confirm',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => this.executeSave()
        })
      }else{
        this.executeSave()
      }
      
    },
    executeSave(){
      this.saveLoading = true
      let payload = {...this.data, ...this.prediction}
      QueryService.saveRecordToDB(payload).then((res) => {
        this.savedRef = this.data.ref
        this.$buefy.toast.open({
              duration: 2000,
              message: `Data saved successfully`,
              position: "is-bottom-right",
              type: "is-success",
              hasIcon: false,
            });
          })
          .catch((res) => {
            console.log(res)
            let msg = "Failed to save data. Check console for messages."
            if('data' in res && "message" in res.data){
              msg = "Failed to save data. Received error message "+res.data.message
            }
            this.$buefy.toast.open({
              duration: 4000,
              message: msg,
              position: "is-bottom-right",
              type: "is-danger",
              hasIcon: false,
            });
          })
          .finally(() => (this.saveLoading = false));
    },
    getPrediction() {
      this.predictionLoading = true;
      setTimeout(() => {
        QueryService.getPrediction(this.data)
          .then((res) => {
            this.prediction = res.data;
            console.log(this.prediction)
          })
          .catch((res) => {
            this.error = res;
            this.$buefy.toast.open({
              duration: 4000,
              message: `Could not process prediction due to the following: ${this.error}. Probably malformed data.`,
              position: "is-bottom-right",
              type: "is-danger",
              hasIcon: false,
            });
            console.log(this.error)
          })
          .finally(() => (this.predictionLoading = false));
      }, 500);
    },
  },
};
</script>
